import React, { Component } from "react";
import { AddMenuProps, AddMenuState } from "../../interfaces/menu";
import { connect } from "react-redux";
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import {
  addMenu,
  storeTypeList,
  brandsList,
  logoutUser,
  countryList,
  statesList
} from "../../redux";
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import { Link, Redirect } from "react-router-dom";
import CheckChanges from '../../components/confirmOnLeave'
import { addAggregator } from "../../redux/actions/aggregatorsAction";
import { AddAggregatorProps, AddAggregatorState } from "../../interfaces/aggreagtors";
class AddAggregator extends Component<AddAggregatorProps, AddAggregatorState> {
  constructor(props: any) {
    super(props);
    this.state = {
      aggregator_name:''
    };
    this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentDidMount() {
    // this.props.countryList();
    // this.props.statesList();
    document.title = "SimpleXSync | Aggregators"
  }
  handleInputChange(event: { target: { name: any; value: any } }) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  isAggreagtorReady = () => {
    const { aggregator_name } = this.state;
    return (
      aggregator_name !== ''
    );
  };
  handleSaveBtnClick = (event: any) => {
    let { aggregator_name } = this.state;
    let data = {
      aggregator_name:aggregator_name
    };
    this.props.addAggregator(data);
  };
  render() {
    if (sessionStorage.token) {
      jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
        if (err) {
          this.props.logoutUser();
        }
      });
    } else {
      return <Redirect to="/" />
    }
    let msg;
    let messcolor;
    // let { country } = this.state;
    return (
      <div className="page">
        <CheckChanges path="/add-menu" />
        <Topbar />
        <div className="page-content d-flex align-items-stretch">
          <Sidebar />
          <div className="content-inner">
            {/*  Page Header */}
            <header className="page-header py-0">
              <div className="container-fluid">
                <div className="d-flex justify-content-between py-3">
                  <h4 className="mt-2">Aggregators Management</h4>
                </div>
              </div>
            </header>
            {/* Breadcrumb */}
            <div className="breadcrumb-holder container-fluid">
              <ul className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/menus" className="text-primary">Aggregators</Link></li>
                <li className="breadcrumb-item active">Add Aggregator</li>
              </ul>
            </div>
            <section className="forms">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <strong>
                          <p
                            className="text-center font-weight-light mb-4"
                            style={{ color: messcolor }}
                          >
                            {msg}
                          </p>
                        </strong>
                        <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Aggregator name <span className="text-danger">*</span>
                              </label>
                              <input
                                id="menuname"
                                type="text"
                                name="aggregator_name"
                                required
                                data-msg="Please enter Menu Name"
                                className="input-material"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                        {/* <div className="row">
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                Country <span className="text-danger">*</span>
                              </label>
                              <select
                                name="country"
                                className="form-control mt-2"
                                required
                                data-msg="Please enter Country"
                                onChange={this.handleInputChange}
                              >
                                <option value="">Select Country</option>
                                {countries &&
                                  countries.map((country: any, index: any) => (
                                    <option key={index} value={country.country_id}>
                                      {country.country_name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <label className="form-control-label">
                                States <span className="text-danger">*</span>
                              </label>
                              <select
                                name="state"
                                className="form-control mt-2"
                                required
                                data-msg="Please select state"
                                onChange={this.handleInputChange}
                              >
                                <option value="">Select State</option>
                                {country !== "" &&
                                  states &&
                                  states.map(
                                    (state: any, index: any) =>
                                      state.country_id == country && (
                                        <option key={index} value={state.state_id}>
                                          {state.state_name}
                                        </option>
                                      )
                                  )}
                              </select>
                            </div>
                          </div>
                        </div> */}
                        <div className="form-group d-flex justify-content-end mt-4">
                          {/* <input type="reset" value="cancel" className="btn btn-danger mr-2" /> */}
                          <button onClick={this.handleSaveBtnClick} disabled={!this.isAggreagtorReady()} className="btn btn-primary">Save</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </div>

        </div>
      </div >
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    storetype: state.store.types,
    brands: state.store.brands,
    countries: state.store.countries,
    states: state.setting.states,
    message: state.menu.message,
    isInserted: state.menu.isInserted,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutUser: function () {
      dispatch(logoutUser());
    },
    addMenu: function (data: any) {
      dispatch(addMenu(data));
    },
    brandsList: function () {
      dispatch(brandsList());
    },
    storeTypeList: function () {
      dispatch(storeTypeList());
    },
    countryList: function () {
      dispatch(countryList());
    },
    statesList: function () {
      dispatch(statesList());
    },addAggregator: (data:any) => {
        dispatch(addAggregator(data))
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddAggregator);
