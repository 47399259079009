import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { connect } from 'react-redux'
import { logoutUser, getTaxValue, saveDiscountValue, countryList, saveDeliveryFee, discountsList, statesList, saveTaxByState, brandsList } from '../../redux'
import { SettingsProps, SettingsState } from '../../interfaces/settings';
import Select from 'react-select'
import { statesListByBrand } from '../../redux/actions/settingsAction';
import { aggregatorsList } from '../../redux/actions/aggregatorsAction';
class Settings extends Component<SettingsProps, SettingsState> {
    constructor(readonly props: any) {
        super(props);
        this.state = {
            country: "",
            deliveryCountry: "",
            state: "",
            deliveryState: "",
            tax_value: "",
            delivery_fee: "",
            brand_id:'',
            aggregator_id:''
        }
        this.handleTaxSubmit = this.handleTaxSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
        this.handleDeliveryFeeStateChange = this.handleDeliveryFeeStateChange.bind(this);
    }
    componentDidMount() {
        // this.props.getTaxValue();
        this.props.brandsList()
        this.props.countryList();
        this.props.aggregatorsList()
        // this.props.statesList();
        this.props.discountsList();
        document.title = "SimpleXSync | Global Settings"
    }

    handleBrandsInputChange = (e:any,i:any)=>{
        this.setState({brand_id:e.value})
        this.props.statesList(e.value);
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
        if (event.target.name == 'country') {
            if (event.target.value == "") {
                this.setState({ state: "", tax_value: "" });
            } else {
                if (event.target.value !== this.state.country) {
                    this.setState({ state: "", tax_value: "" });
                }
            }
        } else if (event.target.name == 'deliveryCountry') {
            if (event.target.value == "") {
                this.setState({ deliveryState: "", delivery_fee: "" });
            } else {
                if (event.target.value !== this.state.deliveryCountry) {
                    this.setState({ deliveryState: "", delivery_fee: "" });
                }
            }
        }
    }
    handleStateChange(event: { target: { name: any; value: any; }; }) {
        let { states } = this.props;
        if (event.target.value !== "") {
            let data: any = states.find((state: any) => state.state_id == event.target.value);
            this.setState({ state: data.state_id, tax_value: data.tax_percent ? data.tax_percent : "" });
        } else {
            this.setState({ state: "", tax_value: "" });
        }
    }
    handleDeliveryFeeStateChange(event: { target: { name: any; value: any; }; }) {
        let { states } = this.props;
        if (event.target.value !== "") {
            let data: any = states.find((state: any) => state.state_name == event.target.value);
            this.setState({ deliveryState: data.state_name,state:data.state_id, delivery_fee: data.delivery_fee ? data.delivery_fee : "" });
        } else {
            this.setState({ deliveryState: "", delivery_fee: "" });
        }
    }
    isTaxSubmitReady = () => {
        let { country, state, tax_value,aggregator_id } = this.state;
        return (country !== "" && state !== "" && tax_value !== ""&&aggregator_id!="");
    }
    isCountrySubmitReady = () => {
        let { deliveryCountry, deliveryState, delivery_fee,aggregator_id } = this.state;
        return (deliveryCountry !== "" && deliveryState !== "" && delivery_fee !== "" && aggregator_id!='');
    }
    handleTaxSubmit = (event: any) => {
        let { tax_value, state,aggregator_id,brand_id } = this.state;
        let data = {
            state_id: state,
            tax_percent: tax_value,
            aggregator_id,
            brand_id
        }
        this.props.saveTaxByState(data);
        event.preventDefault()
    }
    blockInvalidChar = (e: any) =>
        ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

    handleDeliveryFeeSubmit = (event: any) => {
        let { delivery_fee, deliveryState,aggregator_id,state,brand_id } = this.state;
        let data = {
            state_name: deliveryState,
            state_id: state,
            delivery_fee: delivery_fee,
            aggregator_id,
            brand_id
        }
        // let { country, delivery_fee } = this.state;
        // let obj = {
        //     country_code: country,
        //     delivery_fee: delivery_fee
        // }
        this.props.saveDeliveryFee(data);
        event.preventDefault()
    }

    handleAggregatorChange = (e:any,type:any)=>{
        this.setState({aggregator_id:e.target.value})
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let token: any = sessionStorage.getItem('token');
        let decoded:any = jwt.decode(token);
        let selectedBrands:any = []
        if(decoded.role_id == 7) {
            selectedBrands = this.props.brands
        } else {
            selectedBrands = this.props.brands.filter((data:any)=>{
                return data.brand_id == decoded.brand_id
            })
        }
        let msg, discmsg;
        let taxcolor, disccolor;
        let deliverymsg, deliverycolor;
        let { country, tax_value, delivery_fee, deliveryCountry } = this.state;
        const { isSaved, message, states, countries, deliveryMsg, saveDelivery,aggregators } = this.props;
        if (!isSaved) { msg = message; taxcolor = 'red' } else { msg = message; taxcolor = 'green' }
        if (!saveDelivery) { deliverymsg = deliveryMsg; deliverycolor = 'red' }
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Global Settings</h4>
                                </div>

                                <div className="row">
                                    <div className="col" >
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Select Brand <span className="text-danger">*</span></label>
                                                                    <Select
                                                                        name="brands"
                                                                        options={selectedBrands}
                                                                        className="text-capitalize basic-multi-select mt-2"
                                                                        classNamePrefix="select"
                                                                        onChange={(e, i) => this.handleBrandsInputChange(e, i)}
                                                                    />
                                                                </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="card shadow rounded">
                                    <div className="row p-3">
                                        <div className="col-lg-6">
                                            <div className="card shadow rounded">
                                                <div className="card-content">
                                                    <div className="card-header border-bottom-grey">
                                                        <strong className="">Global Tax</strong>
                                                    </div>
                                                    <div className="card-body">
                                                        <strong><p className="text-center font-weight-light mb-4" style={{ color: taxcolor }}>
                                                            {msg}
                                                        </p></strong>
                                                        <form className="form-validate">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">Country <span className="text-danger">*</span></label>
                                                                        <select name="country" className="form-control mt-2" required data-msg="Please enter Country" onChange={this.handleInputChange}>
                                                                            <option value="">Select Country</option>
                                                                            {countries &&
                                                                                countries.map((country: any, index: any) => (
                                                                                    <option key={index} value={country.country_id}>{country.country_name}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">Aggregator <span className="text-danger">*</span></label>
                                                                        {/* <Select
                                                                            defaultValue={{ label: 'Select Timezone', value: '' }}
                                                                            options={[{ label: 'Asia/Karachi', value: 'Asia/Karachi' }]}
                                                                        /> */}
                                                                        <select name="aggregator_id" className="form-control mt-2" required data-msg="Please enter Country" onChange={(e)=>{this.handleAggregatorChange(e,'tax')}}>
                                                                            <option value="">Select Aggregator</option>
                                                                            {aggregators && aggregators.map((agg:any)=>{
                                                                                return <option value={agg.id}>{agg.aggregator_name}</option>
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">States <span className="text-danger">*</span></label>
                                                                        <select name="state" className="form-control mt-2" required data-msg="Please select state" onChange={this.handleStateChange}>
                                                                            <option value="">Select State</option>
                                                                            {country !== "" &&
                                                                                states &&
                                                                                states.map((state: any, index: any) => (
                                                                                    state.country_id == country &&
                                                                                    <option key={index} value={state.state_id}>{state.state_name}-({state.payment_method})</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">Tax Value <span className="text-danger">*</span></label>
                                                                        <input id="taxValue" type="number" name="tax_value" min={0} onKeyDown={this.blockInvalidChar} value={tax_value} required data-msg="Please enter Tax value" className="input-material" onChange={this.handleInputChange} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <div className="form-group d-flex justify-content-end">
                                                                <button onClick={this.handleTaxSubmit} disabled={!this.isTaxSubmitReady()} className="btn btn-primary">Save</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="card shadow rounded">
                                                <div className="card-content">
                                                    <div className="card-header border-bottom-grey">
                                                        <strong className="">Delivery Fee</strong>
                                                    </div>
                                                    <div className="card-body">
                                                        <strong><p className="text-center font-weight-light mb-4" style={{ color: deliverycolor }}>
                                                            {deliverymsg}
                                                        </p></strong>
                                                        <form className="form-validate">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">Country <span className="text-danger">*</span></label>
                                                                        <select name="deliveryCountry" className="form-control mt-2" required data-msg="Please enter Country" onChange={this.handleInputChange}>
                                                                            <option>Select Country</option>
                                                                            {countries &&
                                                                                countries.map((country: any, index: any) => (
                                                                                    <option key={index} value={country.country_id}>{country.country_name}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">Aggregator <span className="text-danger">*</span></label>
                                                                        {/* <Select
                                                                            defaultValue={{ label: 'Select Timezone', value: '' }}
                                                                            options={[{ label: 'Asia/Karachi', value: 'Asia/Karachi' }]}
                                                                        /> */}
                                                                        <select name="aggregator_id" className="form-control mt-2" required data-msg="Please enter Country" onChange={(e)=>{this.handleAggregatorChange(e,'delivery_fee')}}>
                                                                            <option value="">Select Aggregator</option>
                                                                            {aggregators && aggregators.map((agg:any)=>{
                                                                                return <option value={agg.id}>{agg.aggregator_name}</option>
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">States <span className="text-danger">*</span></label>
                                                                        <select name="deliveryState" className="form-control mt-2" required data-msg="Please select state" onChange={this.handleDeliveryFeeStateChange}>
                                                                            <option value="">Select State</option>
                                                                            {deliveryCountry !== "" &&
                                                                                states &&
                                                                                states.map((state: any, index: any) => (
                                                                                    (state.country_id == deliveryCountry && state.payment_method == "cash") &&
                                                                                    <option key={index} value={state.id}>{state.state_name}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="form-group">
                                                                        <label className="form-control-label">Delivery Fee <span className="text-danger">*</span></label>
                                                                        <input id="deliveryFee" type="number" name="delivery_fee" min={0}
                                                                            onKeyDown={this.blockInvalidChar} value={delivery_fee} required data-msg="Please enter Delivery Fee" className="input-material" onChange={this.handleInputChange} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <div className="form-group d-flex justify-content-end">
                                                                <button onClick={this.handleDeliveryFeeSubmit} disabled={!this.isCountrySubmitReady()} className="btn btn-primary">Save</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div >
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        message: state.setting.message,
        discounts: state.setting.discounts,
        discountMsg: state.setting.discMsg,
        states: state.setting.states,
        countries: state.store.countries,
        isSaved: state.setting.isSaved,
        isInserted: state.setting.isInserted,
        tax: state.setting.taxData,
        deliveryMsg: state.setting.deliveryMsg,
        saveDelivery: state.setting.saveDelivery,
        brands:state.brand.brands,
        aggregators:state.aggregator.aggregators

    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        countryList: function () {
            dispatch(countryList())
        },
        discountsList: function () {
            dispatch(discountsList())
        },
        statesList: function (brand_id:any) {
            dispatch(statesListByBrand(brand_id))
        },
        saveTaxByState: function (data: any) {
            dispatch(saveTaxByState(data))
        },
        getTaxValue: function () {
            dispatch(getTaxValue());
        },
        saveDiscountValue: function (data: any) {
            dispatch(saveDiscountValue(data))
        },
        saveDeliveryFee: function (data: any) {
            dispatch(saveDeliveryFee(data))
        },
        brandsList: function () {
            dispatch(brandsList())
        },
        aggregatorsList:() => { 
            dispatch(aggregatorsList())
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Settings);