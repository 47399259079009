import React, { Component } from 'react';
import { AddModProps, AddModState } from '../../../interfaces/menu'
import { connect } from 'react-redux'
import { menusList, groupsListByMenuId, modGroupsList, addModifier, logoutUser, brandsList } from '../../../redux'
import { Link, Redirect } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../../secret'
import Topbar from '../../../components/topbar';
import Sidebar from '../../../components/sidebar';
import Footer from '../../../components/footer/main';
import CheckChanges from '../../../components/confirmOnLeave'
import Select from 'react-select';
import { instashop_categories } from '../instahop_categories';
import { modifierAsItems } from '../../../redux/actions/menuAction';
class AddMod extends Component<AddModProps, AddModState> {
    constructor(props: any) {
        super(props);
        this.state = {
            modGroupJson: [],
            modifiername: "",
            erpid: null,
            disabled:false,
            items:[],
            brand_id:null,
            variants:[],
            selectedVariant:{},
            poscode: null,
            priority: "",
            modifiertype: "",
            modifiercost: "",
            modifiersale: "",
            images: null,
            instashop_category:'',
            erp_id:"",
            modifier_as_variant:0
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleModGroupsInputChange = this.handleModGroupsInputChange.bind(this);
    }
    componentDidMount() {
        this.props.brandsList()
        document.title = "SimpleXSync | Modifiers"
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleInstashopCategor = (e:any) => {
        this.setState({instashop_category:e.value})
    }
    handleModGroupsInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ modGroupJson: e });
        }
    };

    handleItemInputChange = (e: any, index: any) => {
        let itemVars:any = []
        for(let i = 0;i<e.variants.length;i++){
            itemVars.push({label:e.variants[i].size,value:e.variants[i].id,variant:e.variants[i]})
        }
       this.setState({variants: itemVars})
    };

    handleModifierChange = (e: any, index: any) => {
        let data:any = this.state.variants.filter((variant:any)=>{
            return variant.value == e.value
        })
        console.log("data",data)
        if(data && data.length > 0) {
            this.setState({selectedVariant: data[0].variant,modifiername:data[0].variant.size,modifiercost:data[0].variant.cost,modifiersale:data[0].variant.mrp,erp_id:data[0].variant.erp_id})
        }
     };

    handleModifierType = (e: any, index: any) => {
            this.setState({ modifier_as_variant: e.value });
    };
    fileSelectedHandler = (e: any) => {
        if (e.target.files.length > 0) {
            this.setState({ images: e.target.files })
        }
    }
    isModifierReady = () => {
        const { modifiername, modGroupJson, modifiercost, modifiersale, images,instashop_category,disabled } = this.state
        return (modifiername !== "" && disabled!=true && (modGroupJson && modGroupJson.length > 0) && modifiercost !== "" && modifiersale !== "" && (images && images.length > 0));
    }
    handleState = (value:any)=>{
        this.setState({disabled:value})
    }
    handleSaveBtnClick = (event: any) => {
        this.setState({disabled:true})
        let { modGroupJson, erpid,brand_id, poscode,priority,instashop_category, modifiername, modifiertype, modifiercost, modifiersale,erp_id } = this.state;
        const data = new FormData()
        if (this.state.images) {
            for (var x = 0; x < this.state.images.length; x++) {
                data.append('files', this.state.images[x])
            }
        }
        data.append('modifier_name', modifiername)
        data.append('modifier_type', modifiertype)
        data.append('erp_id', erp_id)
        data.append('modifier_cost_price', modifiercost)
        data.append('modifier_sale_price', modifiersale)
        data.append('priority', priority)
        data.append("instashop_category",instashop_category)
        data.append('mod_groups_json', JSON.stringify(modGroupJson))
        data.append("brand_id",brand_id)
        this.props.addModifier(data,this.handleState);
    }
    handleBrandsInputChange = (e:any,i:any)=>{
        this.setState({brand_id:e.value})
        this.props.modGroupsList("master",e.value)
        this.props.modifierAsItems("master",e.value)
    }
    render() {
        let items:any = []
        for(let i=0;i<this.props.item_modifiers.length;i++) {
            items.push({label:this.props.item_modifiers[i].item_name,value:this.props.item_modifiers[i].menu_item_id,variants:this.props.item_modifiers[i].variants});
        }
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let msg;
        let messcolor;
        const { modGroupJson,modifiername,modifiercost,modifiersale,erp_id } = this.state;
        const { isInserted, message, modgroups } = this.props;
        if (!isInserted) {
            msg = message;
            messcolor = 'red'
        }
        let token: any = sessionStorage.getItem('token');
        let decoded:any = jwt.decode(token);
        let role_id:any = decoded.role_id
        let selectedBrands:any = []
        if(decoded.role_id == 7) {
            selectedBrands = this.props.brands
        } else {
            selectedBrands = this.props.brands.filter((data:any)=>{
                return data.brand_id == decoded.brand_id
            })
        }
        return (
            <div className="page">
                <CheckChanges path="/add-modifier" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Modifier Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/modifiers" className="text-primary">Modifiers</Link></li>
                                <li className="breadcrumb-item active">Add Modifier</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Use Item Size as Modifier<span className="text-danger">*</span></label>
                                                            <Select
                                                                name="groups"
                                                                value={{label:this.state.modifier_as_variant == 0 ? 'No' : 'Yes',value:this.state.modifier_as_variant}}
                                                                options={[{label:"Yes",value:1},{label:"No",value:0}]}
                                                                className="text-capitalize basic-multi-select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleModifierType(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col'>
                                                            <label className="form-control-label">Select Brand <span className="text-danger">*</span></label>
                                                            <div className="form-group">
                                                                <Select
                                                                    name="brands"
                                                                    options={selectedBrands}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    // value={{label:this.state.brand_name,value:this.state.brand_id}}
                                                                    onChange={(e, i) => this.handleBrandsInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                </div>
                                                {this.state.modifier_as_variant==0 && <div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Modifier name <span className="text-danger">*</span></label>
                                                                <input id="modifiername" type="text" name="modifiername" required data-msg="Please enter Modifier Name" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>

                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Erp Id <span className="text-danger">*</span></label>
                                                                <input id="erp_id" type="text" name="erp_id" required data-msg="Please enter Modifier Name" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Modifier Group <span className="text-danger">*</span></label>
                                                                <Select
                                                                    isMulti
                                                                    name="groups"
                                                                    value={modGroupJson}
                                                                    options={modgroups}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleModGroupsInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                        {/* <div className="col" >
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Instashop Category <span className="text-danger">*</span></label>
                                                                    <Select
                                                                        name="instashop_category"
                                                                        options={instashop_categories}
                                                                        placeholder='Select Instashop Category'
                                                                        className="text-capitalize basic-multi-select mt-2"
                                                                        classNamePrefix="select"
                                                                        onChange={(e, i) => this.handleInstashopCategor(e)}
                                                                    />
                                                                </div>
                                                        </div> */}
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Priority</label>
                                                                <input id="priority" type="text" name="priority" required data-msg="Please enter Priority" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-5">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Type</label>
                                                                <input id="modifiertype" type="text" name="modifiertype" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col-7">
                                                            <div className="form-group">
                                                                <label className="form-control-label mb-3">Modifier Image <span className="text-danger">*</span></label>
                                                                <input id="files" type="file" name="files" className="form-control-file" onChange={this.fileSelectedHandler} multiple />
                                                                <small className="form-text">You can also choose multiple images.</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Cost Price <span className="text-danger">*</span></label>
                                                                <input id="modifiercost" type="number" name="modifiercost" required data-msg="Please enter Cost Price" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Sale Price <span className="text-danger">*</span></label>
                                                                <input id="modifiersale" type="number" name="modifiersale" required data-msg="Please enter Sale Price" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}

                                                {this.state.modifier_as_variant==1 && <div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Item<span className="text-danger">*</span></label>
                                                                <Select
                                                                    name="items"
                                                                    options={items}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleItemInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='col'>
                                                            <div className="form-group">
                                                                <label className="form-control-label">Item Size<span className="text-danger">*</span></label>
                                                                <Select
                                                                    name="variants"
                                                                    options={this.state.variants}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleModifierChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Modifier name <span className="text-danger">*</span></label>
                                                                <input disabled value={modifiername} id="modifiername" type="text" name="modifiername" required data-msg="Please enter Modifier Name" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>

                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Erp Id <span className="text-danger">*</span></label>
                                                                <input value={erp_id} disabled id="erp_id" type="text" name="erp_id" required data-msg="Please enter Modifier Name" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Modifier Group <span className="text-danger">*</span></label>
                                                                <Select
                                                                    isMulti
                                                                    name="groups"
                                                                    value={modGroupJson}
                                                                    options={modgroups}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleModGroupsInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                        {/* <div className="col" >
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Instashop Category <span className="text-danger">*</span></label>
                                                                    <Select
                                                                        name="instashop_category"
                                                                        options={instashop_categories}
                                                                        placeholder='Select Instashop Category'
                                                                        className="text-capitalize basic-multi-select mt-2"
                                                                        classNamePrefix="select"
                                                                        onChange={(e, i) => this.handleInstashopCategor(e)}
                                                                    />
                                                                </div>
                                                        </div> */}
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Priority</label>
                                                                <input id="priority" type="text" name="priority" required data-msg="Please enter Priority" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-5">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Type</label>
                                                                <input id="modifiertype" type="text" name="modifiertype" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col-7">
                                                            <div className="form-group">
                                                                <label className="form-control-label mb-3">Modifier Image <span className="text-danger">*</span></label>
                                                                <input id="files" type="file" name="files" className="form-control-file" onChange={this.fileSelectedHandler} multiple />
                                                                <small className="form-text">You can also choose multiple images.</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Cost Price <span className="text-danger">*</span></label>
                                                                <input value={modifiercost} disabled id="modifiercost" type="number" name="modifiercost" required data-msg="Please enter Cost Price" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Sale Price <span className="text-danger">*</span></label>
                                                                <input value={modifiersale} disabled id="modifiersale" type="number" name="modifiersale" required data-msg="Please enter Sale Price" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    <button className='btn btn-primary' disabled={!this.isModifierReady()} onClick={this.handleSaveBtnClick}>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div >
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        menus: state.menu.menus,
        groups: state.menu.groups,
        modgroups: state.menu.modgroups,
        message: state.menu.message,
        isInserted: state.menu.isInserted,
        item_modifiers: state.menu.modifiers_as_items,
        brands:state.brand.brands
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        addModifier: function (data: any,handleState:any) {
            dispatch(addModifier(data,handleState));
        },
        menusList: function () {
            dispatch(menusList())
        },
        groupsListByMenuId: function (id: any) {
            dispatch(groupsListByMenuId(id))
        },
        modGroupsList: function (type:any,brand_id:any) {
            dispatch(modGroupsList(type,brand_id))
        },
        modifierAsItems:(type:any,brand_id:any)=>{
            dispatch(modifierAsItems(type,brand_id))
        },
        brandsList: function () {
            dispatch(brandsList())
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddMod);