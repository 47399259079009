import { AGGREGATOR, AGGREGATORS } from '../actions/aggregatorsType'
const initialState: any = {
    aggregators:[] || '',
    aggregator:{}
}

const aggregatorReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case AGGREGATORS: return {
            ...state,
            aggregators: action.payload
        }
        case AGGREGATOR: return {
            ...state,
            aggregator: action.payload
        }
        default: return state;
    }
}
export default aggregatorReducer;