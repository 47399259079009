import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Footer from '../components/footer/login'
import { ForgotPassState, ForgotPassProps } from '../interfaces/user'
import { connect } from 'react-redux'
import { forgotPassword } from '../redux'

class ForgotPass extends Component<ForgotPassProps, ForgotPassState> {
    constructor(readonly props: any) {
        super(props);
        this.state = {
            email: ""
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        document.title = "SimpleXSync | ForgotPassword"
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    handleSubmit = (event: any) => {
        event.preventDefault();
        let { email } = this.state;
        this.props.forgotPassword(email);
    }
    render() {
        let msg;
        let messcolor;
        const { isReset, message } = this.props;
        if (isReset) {
            msg = message;
            messcolor = 'green'
        } else {
            msg = message;
            messcolor = 'red'
        }
        return (
            <div className="page login-page">
                <div className="container d-flex align-items-center">
                    <div className="form-holder has-shadow">
                        <div className="row">
                            {/* Logo & Information Panel */}
                            <div className="col-lg-6">
                                <div className="info d-flex align-items-center justify-content-center">
                                    <div className="content">
                                        <div className="logo">
                                            <img src={process.env.PUBLIC_URL + '/assets/img/logo.png'} width="235" height="235" alt="..." className="img-fluid ml-3" />
                                            <h1 className="text-center mt-3">Simplex Sync</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* htmlForm Panel  */}
                            <div className="col-lg-6 bg-white">
                                <div className="form d-flex align-items-center">
                                    <div className="content">
                                        <strong><p className="text-center font-weight-light" style={{ color: messcolor }}>
                                            {msg}
                                        </p></strong>
                                        <form className="form-validate">
                                            <div className="form-group">
                                                <label className="form-control-label">Email</label>
                                                <input id="login-username" type="text" name="email" required data-msg="Please enter your email" className="input-material" onChange={this.handleInputChange} />
                                            </div>
                                            <button id="reset" onClick={this.handleSubmit} className="btn btn-primary">Reset Password</button>
                                        </form>
                                        <Link to="/" className="mt-4 text-primary">Login</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
};
const mapStateToProps = (state: any) => {
    return {
        message: state.user.message,
        isReset: state.user.isReset
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        forgotPassword: function (email: any) {
            dispatch(forgotPassword(email));
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPass);