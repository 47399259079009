import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { connect } from 'react-redux'
import { menusList, groupsListByMenuId, logoutUser, publishMenu, publishLater, countryList, statesList } from '../../redux'
import { MenuProps } from '../../interfaces/menu';
import { aggregatorsList, blockUnblockAggregator } from '../../redux/actions/aggregatorsAction';
class ActionFormatter extends Component<{ row: any, data: any }, { [x: number]: any, activeTab: any, date: any, time: any }> {
    constructor(readonly props: any) {
        super(props);
        this.state = {
            activeTab: 0,
            date: "",
            time: ""
        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    showgroups = (id: any) => {
        this.props.data.groupsListByMenuId(id)
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    ispublishReady = () => {
        const { date } = this.state
        return (date !== "");
    }
    handleUnPublish = (id: any) => {
        let data = { is_publish: 0 }
        this.props.data.publishMenu(id, data,this.props.row.menu_name)
    }
    handlePublish = (id: any) => {
        let { activeTab, date, time } = this.state;
        let { row } = this.props
        if (activeTab === 0) {
            let data = {}
            if (row.cron_job_id) {
                data = {
                    is_publish: 1,
                    cron_job_id: row.cron_job_id
                }
            } else {
                data = { is_publish: 1 }
            }
            this.props.data.publishMenu(id, data,row.menu_name)
        } else {
            let data = {
                date: new Date(date),
                time: time,
                is_publish: 1
            }
            this.props.data.publishLater(id, data,row.menu_name)
        }
    }
    render() {
        const { row, data } = this.props;
        const today = new Date().toISOString().split('T')[0];
        return (
            <div>
                <button title={row.is_active === 0 ? "UnBlock" : "Block"} data-toggle="modal" data-target={`#blockunblock${row.id}`} className={row.is_active === 0 ? "btn btn-outline-success" : "btn btn-outline-danger"}><i className={row.is_active === 0 ? "fa fa-unlock" : "fa fa-lock"}></i></button>
                <Link title="Edit Menu" className="btn btn-outline-primary ml-2" to={`/edit-aggregator/${row.id}`}><i className="fa fa-edit"></i></Link>
                
                <div id={`blockunblock${row.id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">{row.is_active === 0 ? "UnBlock" : "Block"} Aggregator</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure,you want to {row.is_active === 0 ? "UnBlock" : "Block"} this Aggregator?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                <button onClick={() =>  this.props.data.blockUnblockAggregator(row.id)} className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
function actionFormatter(cell: any, row: any, props: any) {
    return (
        <ActionFormatter row={row} data={props} />
    );
}

class Aggregators extends Component<{aggregatorsList:()=>{},logoutUser: () => {},data:any,blockUnblockAggregator:(id:number)=>{}}, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    componentDidMount() {
        this.props.aggregatorsList();
        document.title = "SimpleXSync | Aggregators"
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const options: any = {
            // sizePerPageList: [5,10,15],
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            insertModal: () => { return <Redirect to="/add-aggregator" /> },
            noDataText: 'Aggregators Not Found'
        };
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Aggregators Management</h4>
                                </div>
                            </div>
                        </header>
                        <section className="tables">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body">
                                                <BootstrapTable version='4' data={this.props.data} search={true} pagination={this.props.data.length > 10 && true} options={options} exportCSV={true} insertRow csvFileName='menus.csv' hover>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='id' csvHeader='#' width='100' dataSort={true} isKey>#</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='aggregator_name' csvHeader='Menu Name' width='100' columnTitle>Aggregator name</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='action' width='230' dataFormat={actionFormatter} formatExtraData={this.props} export={false}>Action</TableHeaderColumn>
                                                </BootstrapTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
;
function statusFormatter(cell: any) {
    return (
        <div>
            <span {...(cell == 1 && { className: "badge badge-success p-2" }) || (cell == 0 && { className: "badge badge-danger p-2" })}>{cell == 1 ? "published" : "Un published"}</span>
        </div>
    )
}
const mapStateToProps = (state: any) => {
    return {
        data: state.aggregator.aggregators,
        groups: state.menu.groups
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },aggregatorsList: function () {
            dispatch(aggregatorsList())
        },blockUnblockAggregator:(id:number) => {
            dispatch(blockUnblockAggregator(id))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Aggregators);