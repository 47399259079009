import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Topbar from "../../components/topbar";
import Sidebar from "../../components/sidebar";
import Footer from "../../components/footer/main";
import jwt from "jsonwebtoken";
import { secretKey } from "../../secret";
import { connect } from "react-redux";
import { franchiseList, logoutUser } from "../../redux";
import { FranchiseProps } from "../../interfaces/customers";

class ActionFormatter extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    render() {
        const { row } = this.props;
        const FranchiseDate = new Date(row.date_created).toLocaleDateString();
        return (
            <div>
                <button
                    title="View Franchise Form"
                    className="btn btn-outline-primary"
                    data-toggle="modal"
                    data-target={`#ViewFranchise${row.id}`}
                >
                    <i className="fa fa-eye"></i>
                </button>
                {/* <!-- Modal--> */}
                <div
                    id={`ViewFranchise${row.id}`}
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                    className="modal fade text-left"
                >
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">
                                    Franchise Form
                                </h4>
                                <button
                                    type="button"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    className="close"
                                >
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-row" >
                                    {/* <div className="form-group col-md-12"> <br /></div>
                                            <div className="form-group col-md-12"><h4 id="txt">Feedback Form</h4></div> */}
                                    <div className="form-group col-md-6">
                                        <h5 id="txt">Occupation</h5>
                                        <input name="occupation" disabled defaultValue={row.occupation} style={{ fontSize: "15px" }} type="text" className="form-control bg-white" id="round" required />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <h5 id="txt">Form Date </h5>
                                        <input type="text" name="FranchiseDate" disabled defaultValue={FranchiseDate} required data-msg="Please Enter Order Date" className="form-control bg-white" id="round" />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <h5 id="txt">Do you own property at this location?</h5>
                                        <input name="own_property" disabled defaultValue={row.own_property} style={{ fontSize: "15px" }} type="text" className="form-control bg-white" id="round" required />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <h5 id="txt">Have you ever owned a business or a franchise?</h5>
                                        <input type="text" name="own_business" disabled defaultValue={row.own_business} style={{ fontSize: "15px" }}required className="form-control bg-white" id="round" />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <h5 id="txt">Postal Address</h5>
                                        <input name="street_address" disabled defaultValue={row.street_address} style={{ fontSize: "15px" }} type="text" className="form-control bg-white" id="round" required />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <h5 id="txt">Message</h5>
                                        <textarea name="Description" id="round2" disabled style={{ width: '100%', height: '100px' }}>
                                            {row.description}
                                        </textarea>
                                    </div>

                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    data-dismiss="modal"
                                    className="btn btn-danger"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
function actionFormatter(cell: any, row: any, props: any) {
    return <ActionFormatter row={row} />;
}
class FranchiseApp extends Component<FranchiseProps, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    componentDidMount() {
        this.props.franchiseList();
        document.title = "SimpleXSync | Franchise Applications"
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />;
        }
        const options: any = {
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3, // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            noDataText: "Franchise Applications Not Found"
        };

        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Franchise Applications Management</h4>
                                </div>
                            </div>
                        </header>
                        <section className="tables">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body">


                                                {this.props.data &&
                                                    <div className="card-body">
                                                        <BootstrapTable version='4' data={this.props.data} search={true} pagination={this.props.data.length > 10 && true} options={options} exportCSV={true} csvFileName='franchiseApplications.csv' hover>
                                                            <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='id' csvHeader='#' width='70' dataSort={true} isKey>#</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='first_name' csvHeader='Name' width='120' columnTitle dataSort={true}>Name</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='email_address' csvHeader='Email' width='150' columnTitle>Email</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='phone_number' csvHeader='Phone' width='120' columnTitle>Phone</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='country' csvHeader='Country' width='100' columnTitle>Country</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='city' csvHeader='City' width='100' columnTitle>City</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='occupation' csvHeader='Occupation' width='120' columnTitle>Occupation</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='street_address' csvHeader='Street Address' width='150' columnTitle>Postal Address</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='own_business' csvHeader='Business/Franchise' width='100' columnTitle>Buisness</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='own_property' csvHeader='Property' width='100' columnTitle>Property</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='description' csvHeader='Message' width='150' columnTitle>Message</TableHeaderColumn>
                                                            <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='action' width='100' dataFormat={actionFormatter} formatExtraData={this.props} export={false}>Action</TableHeaderColumn>
                                                        </BootstrapTable>
                                                    </div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        data: state.customer.franchiseApplications
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        franchiseList: function () {
            dispatch(franchiseList());
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(FranchiseApp);
