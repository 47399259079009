
import Api from '../../components/Api';
import { HANDLE_INPUT, TRADEZONE_LIST, GET_TRADEZONE,HANDLE_AREA_INPUT, TRADEAREA_LIST, GET_TRADEAREA } from './tradezoneType';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

//TradeZone
export const handleZoneInput = (event: { target: { name: any; value: any; }; }) => {
    return {
        type: HANDLE_INPUT,
        input: event.target
    }
}
export const tradeZoneList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/trade-zone/tradeZones', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    response.data.successResponse.forEach((obj: any) => {
                        obj.label = `${obj.delivery_zone_name}(${obj.city})`;
                        obj.value = obj.id;
                    })
                    dispatch({
                        type: TRADEZONE_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const addTradeZone = (data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.post('/trade-zone/add_TradeZone', data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "tradezone";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.message.indexOf("ER_DUP_ENTRY") > -1) {
                            error = 'Store already assigned';
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 5000 })
                } else {
                    alert(err.message)
                }
            });
    }
}
export const editTradeZone = (id: any, data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.put(`/trade-zone/edit_TradeZone/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/tradezone";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0];
                    }
                    toast.error(error, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 5000 })
                } else {
                    alert(err.message)
                }
            });
    }
}
export const getTradeZone = (id: number) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/trade-zone/tradeZone/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GET_TRADEZONE,
                        payload: response.data.successResponse,
                        tradeZoneData:response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const delTradeZone = (id: number, history: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.delete(`/trade-zone/del_TradeZone/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    history.push("/tradezone")
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const blockunblockTradezone = (id: any, isActive: any, history: any) => {
    return function (dispatch: any) {
        let is_active;
        if (isActive === 0) {
            is_active = 1
        } else if (isActive === 1) {
            is_active = 0
        }
        let token: any = sessionStorage.getItem('token');
        Api.put(`/trade-zone/tradeZone_block_unblock/${id}`, {
            is_active: is_active
        }, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    history.push("/tradezone")
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

//TradeAreas
export const handleTradeAreaInput = (event: { target: { name: any; value: any; }; }) => {
    return {
        type: HANDLE_AREA_INPUT,
        input: event.target
    }
}
export const tradeAreaList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/trade-zone/tradeAreas', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: TRADEAREA_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const addTradeArea = (data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.post('/trade-zone/add_TradeArea', data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/tradearea";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 5000 })
                } else {
                    alert(err.message)
                }
            });
    }
}
export const editTradeArea = (id: any, data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.put(`/trade-zone/edit_TradeArea/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/tradearea";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0];
                    }
                    toast.error(error, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 5000 })
                } else {
                    alert(err.message)
                }
            });
    }
}
export const getTradeArea = (id: number) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/trade-zone/tradeArea/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GET_TRADEAREA,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const delTradeArea = (id: number, history: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.delete(`/trade-zone/del_TradeArea/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    history.push("/tradearea")
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const blockunblockTradearea = (id: any, isActive: any, history: any) => {
    return function (dispatch: any) {
        let is_active;
        if (isActive === 0) {
            is_active = 1
        } else if (isActive === 1) {
            is_active = 0
        }
        let token: any = sessionStorage.getItem('token');
        Api.put(`/trade-zone/tradeArea_block_unblock/${id}`, {
            is_active: is_active
        }, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    history.push("/tradearea")
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}