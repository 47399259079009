import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Topbar from '../../../components/topbar'
import Sidebar from '../../../components/sidebar'
import Footer from '../../../components/footer/main'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../../secret'
import { connect } from 'react-redux'
import { groupsList,blockunblockgroup, logoutUser, brandsList } from '../../../redux'
import { GroupProps } from '../../../interfaces/menu';
import { groupsListFilter, itemsListByGroupId } from '../../../redux/actions/menuAction';
import { aggregatorsList } from '../../../redux/actions/aggregatorsAction';
import Select from 'react-select'
class ActionFormatter extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    showitems = (id: any) => {
        this.props.itemsdata.itemsListByGroupId(id)
    }
    handleBlockUnblock = (id: any, is_active: any) => {
        let menu_type = this.props.row.aggregator_id ? this.props.row.aggregator_id : 'master'
        this.props.itemsdata.blockunblockgroup(id, is_active,this.props.row.group_name,menu_type);
    };
    render() {
        const { row, itemsdata } = this.props
        console.log("this.props",row)
        return (
            <div>
                <button title={row.is_active === 0 ? "UnBlock" : "Block"} data-toggle="modal" data-target={`#blockunblock${row.group_id}`} className={row.is_active === 0 ? "btn btn-outline-success mr-2" : "btn btn-outline-danger mr-2"}><i className={row.is_active === 0 ? "fa fa-unlock" : "fa fa-lock"}></i></button>
                <button title="View Items" data-toggle="modal" data-target={`#viewitems${row.group_id}`} className="btn btn-outline-info" onClick={() => this.showitems(row.group_id)}><i className="fa fa-list"></i></button>
                <Link title="Edit Group" className="btn btn-outline-primary ml-2" to={`/edit-group/${row.group_id}/${row.aggregator_id ? row.aggregator_id : 'master'}`}><i className="fa fa-edit"></i></Link>
                {/* <!-- Modal--> */}
                <div id={`viewitems${row.group_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Menu Items</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group text-capitalize">
                                            {(itemsdata.items.length > 0) ?
                                                <BootstrapTable version='4' data={itemsdata.items} hover>
                                                    <TableHeaderColumn dataField="menu_item_id" columnTitle isKey>#</TableHeaderColumn>
                                                    <TableHeaderColumn dataField='item_name' columnTitle>Menu Item Name</TableHeaderColumn>
                                                </BootstrapTable> :
                                                <p className="text-center">Items not found</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Block/Unblock Modal--> */}
                <div id={`blockunblock${row.group_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">{row.is_active === 0 ? "UnBlock" : "Block"} Group</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure,you want to {row.is_active === 0 ? "UnBlock" : "Block"} this group?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-secondary">Close</button>
                                <button onClick={() => this.handleBlockUnblock(row.group_id, row.is_active)} className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
function actionFormatter(cell: any, row: any, props: any) {
    return (
        <ActionFormatter row={row} itemsdata={props} />
    );
}
function orderModesFormatter(cell: any, row: any, props: any) {
    return (
        <OrderModesFormatter row={row} data={props} />
    );
}
class OrderModesFormatter extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    IsJsonString = (str: any) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    render() { 
        const { row } = this.props;
        return (
            <div>
                {row.mode &&
                    <button title="View Modes" data-toggle="modal" data-target={`#groupmodes${row.group_id}`} className="btn btn-outline-info"><i className="fa fa-list"></i></button>
                }
                {/* <!-- Modal--> */}
                <div id={`groupmodes${row.group_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Order Modes</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group text-capitalize">
                                            <BootstrapTable version='4' data={(row.mode && this.IsJsonString(row.mode)) ? JSON.parse(row.mode) : ""} hover>
                                                <TableHeaderColumn dataField='label' columnTitle isKey>Order Mode</TableHeaderColumn>
                                            </BootstrapTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
class Groups extends Component<GroupProps, {menu_type:any,brand_id:any}> {
    constructor(readonly props: any) {
        super(props);
        this.state = {
            menu_type:'',
            brand_id:null
        }
    }
    componentDidMount() {
        this.props.aggregatorsList()
        this.props.brandsList()
        let token: any = sessionStorage.getItem('token');
        let decoded:any = jwt.decode(token);
        let role_id:any = decoded.role_id
        if(role_id != 7) {
            this.setState({brand_id:decoded.brand_id})
        }
        document.title = "SimpleXSync | Groups"
        
    }

    handleMenuType = (e:any) => {
        this.setState({menu_type:e.target.value})
        if(this.state.brand_id) {
            this.props.groupsList(e.target.value,this.state.brand_id)
        }
    }

    handleSubmit = () => {
        this.props.groupsList(this.state.menu_type)
    }
    handleBrandsInputChange = (e:any,i:any)=>{
        this.setState({brand_id:e.value})
        this.props.groupsList(this.state.menu_type,e.value);
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let token: any = sessionStorage.getItem('token');
        let decoded:any = jwt.decode(token);
        let role_id:any = decoded.role_id
        let selectedBrands:any = []
        if(decoded.role_id == 7) {
            selectedBrands = this.props.brands
        } else {
            selectedBrands = this.props.brands.filter((data:any)=>{
                return data.brand_id == decoded.brand_id
            })
        }
        const options: any = {
            // sizePerPageList: [5,10,15],
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            insertModal: () => { return <Redirect to="/add-group" /> },
            noDataText: 'Groups Not Found'
        };
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Groups Management</h4>
                                </div>
                            </div>
                        </header>
                        <section className="tables">
                            <div className="container-fluid">
                            <div className="row">
                                    <div className='col-1'>
                                            <label className="form-control-label">
                                                Menu Type <span className="text-danger">*</span>
                                            </label>
                                    </div>
                                    <div className="col-11">
                                        <div className="form-group">
                                            <select
                                                name="menutype"
                                                className="form-control text-capitalize mt-2"
                                                required
                                                data-msg="Please select Menu"
                                                onChange={this.handleMenuType}
                                            >
                                                <option value="">Select MenuType</option>
                                                <option value="master">Master Menu</option>
                                                {/* <option value="aggregator">Aggregator Menu</option> */}
                                                {this.props.aggregators.map((data:any)=>{
                                                   return <option value={data.id}>{data.aggregator_name}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    {/* <div className="col-3">
                                        <br />
                                        <button onClick={this.handleSubmit} className="btn btn-primary mt-3">Submit</button>
                                    </div> */}
                                </div>
                                {role_id == 7 && <div className='row'>
                                    <div className='col-1'>
                                        <label className="form-control-label">Select Brand <span className="text-danger">*</span></label>
                                    </div>
                                                <div className="col-11" >
                                                        <div className="form-group">
                                                            <Select
                                                                name="brands"
                                                                options={selectedBrands}
                                                                className="text-capitalize basic-multi-select mt-2"
                                                                classNamePrefix="select"
                                                                // value={{label:this.state.brand_name,value:this.state.brand_id}}
                                                                onChange={(e, i) => this.handleBrandsInputChange(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                </div>}
                                <div className="row">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body text-capitalize">
                                                <BootstrapTable version='4' data={this.props.data} search={true} pagination={this.props.data.length > 10 && true} options={options} exportCSV={true} insertRow csvFileName='groups.csv' hover>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='group_id' csvHeader='#' width='50' dataSort={true} isKey>#</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='group_name' csvHeader='Group Name' width='100' columnTitle>Group name</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='erp_id' csvHeader='Erp Id' width='100' columnTitle>Erp Id</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='priority' csvHeader='Priority' width='100' columnTitle>Priority</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='menu_name' csvHeader='Menu Name' width='100' columnTitle>Menu</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='action' width='120' dataFormat={actionFormatter} formatExtraData={this.props} export={false}>Action</TableHeaderColumn>
                                                </BootstrapTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
;
const mapStateToProps = (state: any) => {
    return {
        data: state.menu.groups,
        items: state.menu.items,
        aggregators:state.aggregator.aggregators,
        brands:state.brand.brands
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        groupsList: function (menu_type:any,brand_id:any) {
            dispatch(groupsListFilter(menu_type,brand_id))
        },
        blockunblockgroup: function (id: any, is_active: any,group_name:any,menu_type:any) {
            dispatch(blockunblockgroup(id, is_active,group_name,menu_type))
        },
        itemsListByGroupId: function (id: any) {
            dispatch(itemsListByGroupId(id))
        },
        aggregatorsList:() => { 
            dispatch(aggregatorsList())
        },
        brandsList: function () {
            dispatch(brandsList())
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Groups);