import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Topbar from '../../../components/topbar'
import Sidebar from '../../../components/sidebar'
import Footer from '../../../components/footer/main'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../../secret'
import { connect } from 'react-redux'
import { modifierList, deleteModifier, logoutUser, brandsList } from '../../../redux'
import { ModProps } from '../../../interfaces/menu';
import { currency,hungerstation_currency } from '../../../client-config'
import { aggregatorsList } from '../../../redux/actions/aggregatorsAction';
import Select from 'react-select'

function priceFormatter(cell: any,state:any) {
    
    if(state.aggregator_id==4)
    {
        return `${hungerstation_currency}.${cell}`;
    }
   
 
    return `${currency}.${cell}`;
}
class ActionFormatter extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    handleDelete = (id: any) => {
        let type = this.props.row.aggregator_id ? this.props.row.aggregator_id : 'master'
        this.props.delete(id,this.props.row.modifier_name,type)
    };
    render() {
        const { row } = this.props
        return (
            <div>
                <button title="Delete Modifier" className={row.is_active === 0 ? "btn btn-outline-success" : "btn btn-outline-danger"} data-toggle="modal" data-target={`#DelMod${row.modifier_id}`}><i className={row.is_active === 0 ? "fa fa-unlock" : "fa fa-lock"}></i></button>
                <Link title="Edit Modfier" className="btn btn-outline-primary ml-2" to={`/edit-modifier/${row.modifier_id}/${row.aggregator_id ? row.aggregator_id : "master" }`}><i className="fa fa-edit"></i></Link>
                {/* <!-- Modal--> */}
                <div id={`DelMod${row.modifier_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Delete Modifier</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure,you want to delete this Modifier item?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                                <button onClick={() => this.handleDelete(row.modifier_id)} className="btn btn-primary">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
class ModGroupsFormatter extends Component<{ row: any }, {}> {
    constructor(readonly props: any) {
        super(props);
    }
    IsJsonString = (str: any) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    render() {
        const { row } = this.props;
        return (
            <div>
                {row.mod_groups_json &&
                    <button title="View Mod Groups" data-toggle="modal" data-target={`#modGroups${row.modifier_id}`} className="btn btn-outline-info"><i className="fa fa-list"></i></button>
                }
                {/* <!-- Modal--> */}
                <div id={`modGroups${row.modifier_id}`} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" className="modal fade text-left">
                    <div role="document" className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 id="exampleModalLabel" className="modal-title">Mod Groups</h4>
                                <button type="button" data-dismiss="modal" aria-label="Close" className="close"><span aria-hidden="true">×</span></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group text-capitalize">
                                            <BootstrapTable version='4' data={(row.mod_groups_json && this.IsJsonString(row.mod_groups_json)) ? JSON.parse(row.mod_groups_json) : ""} hover>
                                                <TableHeaderColumn dataField='value' csvHeader='#' width='50' dataSort={true} isKey>#</TableHeaderColumn>
                                                <TableHeaderColumn dataField='label' columnTitle>Mod Group Name</TableHeaderColumn>
                                            </BootstrapTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" data-dismiss="modal" className="btn btn-danger">Close</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
function actionFormatter(cell: any, row: any, props: any) {
    return (
        <ActionFormatter row={row} delete={props.deleteModifier} />
    );
}
function modGroupsFormatter(cell: any, row: any, props: any) {
    return (
        <ModGroupsFormatter row={row} data={props} />
    );
}
class Modifiers extends Component<ModProps, {menu_type:any,brand_id:any}> {
    constructor(readonly props: any) {
        super(props);
        this.state = {
            menu_type:"",
            brand_id:null
        }
    }
    componentDidMount() {
        this.props.aggregatorsList()
        this.props.brandsList()
        let token: any = sessionStorage.getItem('token');
        let decoded:any = jwt.decode(token);
        let role_id:any = decoded.role_id
        if(role_id != 7) {
            this.setState({brand_id:decoded.brand_id})
        }
        document.title = "SimpleXSync | Modifiers"
    }


    handleMenuType = (e:any) => {
        this.setState({menu_type:e.target.value})
        if(this.state.brand_id) {
            this.props.modifierList(e.target.value,this.state.brand_id)
        }
    }

    handleBrandsInputChange = (e:any,i:any)=>{
        this.setState({brand_id:e.value})
        this.props.modifierList(this.state.menu_type,e.value);
    }

    handleSubmit = () => {
        this.props.modifierList(this.state.menu_type)
    }

    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        const options: any = {
            sizePerPage: 10, // which size per page you want to locate as default
            page: 1,
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            hideSizePerPage: true, //You can hide the dropdown for sizePerPage
            insertModal: () => { return <Redirect to="/add-modifier" /> },
            noDataText: 'Modifiers Not Found'
        };
        let token: any = sessionStorage.getItem('token');
        let decoded:any = jwt.decode(token);
        let role_id:any = decoded.role_id
        let selectedBrands:any = []
        if(decoded.role_id == 7) {
            selectedBrands = this.props.brands
        } else {
            selectedBrands = this.props.brands.filter((data:any)=>{
                return data.brand_id == decoded.brand_id
            })
        }
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Modifiers Management</h4>
                                </div>
                            </div>
                        </header>
                        <section className="tables">
                            <div className="container-fluid">
                            <div className="row">
                                <div className='col-1'>
                                            <label className="form-control-label">
                                                Menu Type <span className="text-danger">*</span>
                                            </label>
                                </div>
                                    <div className="col-11">
                                        <div className="form-group">
                                        
                                            <select
                                                name="menutype"
                                                className="form-control text-capitalize mt-2"
                                                required
                                                data-msg="Please select Menu"
                                                onChange={this.handleMenuType}
                                            >
                                                <option value="">Select Group</option>
                                                <option value="master">Master Menu</option>
                                                {/* <option value="aggregator">Aggregator Menu</option> */}
                                                {this.props.aggregators.map((data:any)=>{
                                                   return <option value={data.id}>{data.aggregator_name}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    {/* <div className="col-3">
                                        <br />
                                        <button onClick={this.handleSubmit} className="btn btn-primary mt-3">Submit</button>
                                    </div> */}
                                </div>
                                {role_id == 7 && <div className='row'>
                                    <div className='col-1'>
                                        <label className="form-control-label">Select Brand <span className="text-danger">*</span></label>
                                    </div>
                                                <div className="col-11" >
                                                        <div className="form-group">
                                                            <Select
                                                                name="brands"
                                                                options={selectedBrands}
                                                                className="text-capitalize basic-multi-select mt-2"
                                                                classNamePrefix="select"
                                                                // value={{label:this.state.brand_name,value:this.state.brand_id}}
                                                                onChange={(e:any, i:any) => this.handleBrandsInputChange(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                </div>}
                                <div className="row">
                                    <div className="col">
                                        <div className="card">
                                            <div className="card-body text-capitalize">
                                                <BootstrapTable version="4" data={this.props.data} search={true} pagination={this.props.data.length > 10 && true} options={options} exportCSV={true} insertRow csvFileName='modifiers.csv' hover>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='modifier_id' width="120" csvHeader='#' dataSort={true} isKey>#</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='erp_id' width="100" csvHeader='ERP ID' columnTitle>ERP ID</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='pos_code' csvHeader='POS Code' width='100' columnTitle>POS Code</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='modifier_name' width="150" csvHeader='Mod Name' columnTitle>Modifier name</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField="modifier_type" width="100" csvHeader='Type' columnTitle>Type</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='priority' csvHeader='Priority' width='100' columnTitle>Priority</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField="modifier_cost_price" width="120" csvHeader='Cost Price' dataFormat={priceFormatter} formatExtraData={this.state}  columnTitle>Cost Price</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField="modifier_sale_price" width="120" csvHeader='Sale Price' dataFormat={priceFormatter} columnTitle>Sale Price</TableHeaderColumn>
                                                    {/* <TableHeaderColumn dataField='menu_name' csvHeader='Menu Name' columnTitle>Menu name</TableHeaderColumn> */}
                                                    {/* <TableHeaderColumn dataField='group_name' csvHeader='Group Name' columnTitle>Group name</TableHeaderColumn> */}
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField="mod_groups_json" width="120" dataFormat={modGroupsFormatter} export={false}>ModGroup Name</TableHeaderColumn>
                                                    <TableHeaderColumn thStyle={{ fontSize: 15, whiteSpace: 'normal' }} tdStyle={{ fontSize: 15, whiteSpace: 'normal' }} dataField='action' width='120' dataFormat={actionFormatter} formatExtraData={this.props} export={false}>Action</TableHeaderColumn>
                                                </BootstrapTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
;
const mapStateToProps = (state: any) => {
    return {
        data: state.menu.modifiers,
        aggregators:state.aggregator.aggregators,
        brands:state.brand.brands
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        modifierList: function (type:any,brand_id:any) {
            dispatch(modifierList(type,brand_id))
        },
        deleteModifier: function (id: any,modname:any,type:any) {
            dispatch(deleteModifier(id,modname,type))
        },
        aggregatorsList:() => { 
            dispatch(aggregatorsList())
        },
        brandsList: function () {
            dispatch(brandsList())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Modifiers);