import axios from 'axios';
import { API_URL } from '../client-config'

export default axios.create({
    // baseURL: "https://yum.krispy2go.com:3000/",
     baseURL: API_URL
    // baseURL: "https://optpcmsstg.simplexts.com.au:3000/"
    // baseURL: "http://192.168.1.7:3001"
    // baseURL: "http://192.168.18.19:3001"
    // baseURL: 'https://sync-cms.simplexts.com.au:3000/'
    // baseURL: "http://192.168.1.131:8888/"
});