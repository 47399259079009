import {
    MENUS_LIST, GET_MENU, EDIT_MENU, ADD_MENU, GROUPS_LIST, GROUP_OPTIONS, ADD_GROUP, GET_GROUP, EDIT_GROUP,
    ITEMS_LIST, ADD_ITEMS, GET_ITEM, EDIT_ITEM, COMBOS_LIST, ADD_COMBO, GET_COMBO, EDIT_COMBO, COMBO_OPTIONS, MOD_GROUP_LIST, ADD_MOD_GROUP,
    MOD_LIST, ADD_MOD, EDIT_MOD, SEARCH_MODIFIERS, GET_MOD, ING_LIST, ADD_ING, ING_CAT_LIST, ING_UOM_LIST, REC_LIST,
    ADD_REC, GET_MOD_GROUP, CHECK_DUP_POS, STORES_OPTIONS, COMBO_CHOICE_ITEMS, ACTIVE_ITEMS_OPTIONS, CHECK_DUP_ERP,
    GET_BRANCH_MENU, STATUS_CHANGE_REASONS, SEARCH_MENU_ITEM, SEARCH_GROUPS, SEARCH_COMBOS, ORDER_MODES, SUBGROUPS, SUBGROUP, MODIFIER_ITEMS,
    SEARCH_MODIFIER_GROUPS
} from './menuType'
import jwt_decode from "jwt-decode";
import Api from '../../components/Api';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
const IsJsonString = (str: any) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
export const menusList = (type?:any,brand_id?:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/admin/menus/${type}/${brand_id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: MENUS_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const subGroupsByGroup = (group_id:any,type:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/subgroups_by_group_and_type/'+group_id+'/'+type, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: SUBGROUPS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const AggregatorSync = (data:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.post('/menu/aggregator_menu_sync/',data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    toast.info(response.data.successResponse,{position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 2000 })
                    setTimeout(()=>{
                        window.location.href = '/menus'
                    },2000)
                    
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                    toast.error(err.response.data.message,{position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 2000 })
                } else {
                    // alert(err.message)
                    toast.error("Some Error",{position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 2000 })
                }
            });
    }
}

export const subGroupsByGroupByType = (group_id:any,type:any,subgroup_type:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/admin/subgroups_by_group/${group_id}/${type}/${subgroup_type}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: SUBGROUPS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const subGroups = (type:any,brand_id?:any) => {
    return function (dispatch: any) {
        if(type == '') {
            dispatch({
                type: SUBGROUPS,
                payload: []
            })
        } else {
            let token: any = sessionStorage.getItem('token');
            Api.get('/admin/allsubgroups/'+type + '/' + brand_id, {
                headers: { 'Authorization': 'Bearer ' + token }
            })
                .then((response) => {
                    if (response.data.success) {
                        dispatch({
                            type: SUBGROUPS,
                            payload: response.data.successResponse
                        })
                    }
                }).catch(err => {
                    dispatch({
                        type: SUBGROUPS,
                        payload: []
                    })
                    if (err.response) {
                        console.log(err.response.data.message)
                    } else {
                        alert(err.message)
                    }
                });
            }
        
    }
}

export const subGroup = (id:any,type:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/admin/subgroups/${id}/${type}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: SUBGROUP,
                        payload: response.data.successResponse
                    })
                    dispatch(groupsListFilter(type,response.data.successResponse.brand_id))
                    dispatch(storesListForMultiSelect(response.data.successResponse.brand_id))
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const menusListForMaster = (type:any,brand_id?:any) => {
    return function (dispatch: any) {
        if(type == '') {
            dispatch({
                type: MENUS_LIST,
                payload: []
            })
        } else {
            let token: any = sessionStorage.getItem('token');
            Api.get('/admin/menus/' + type + '/' + brand_id, {
                headers: { 'Authorization': 'Bearer ' + token }
            })
                .then((response) => {
                    if (response.data.success) {
                        dispatch({
                            type: MENUS_LIST,
                            payload: response.data.successResponse
                        })
                    }
                }).catch(err => {
                    dispatch({
                        type: MENUS_LIST,
                        payload: []
                    })
                    if (err.response) {
                        console.log(err.response.data.message)
                    } else {
                        alert(err.message)
                    }
                });
            }
        
    }
}

export const menusOptions = (brand_id?:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/menus_options/'+brand_id, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: MENUS_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const editMenusOptions = (menu_type:any,brand_id?:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/edit_menus_options/' + menu_type + '/' + brand_id, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: MENUS_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const addMenu = (data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        data.user_info = jwt_decode(token);
        Api.post('/admin/add_menu', data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/menus";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            error = "Menu already exist"
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ")
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const getMenu = (id: number) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/admin/menu/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GET_MENU,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const getMenuForMasterMenu = (id: number,type:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/admin/menu/${id}/${type}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GET_MENU,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const editMenu = (id: any, data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        data.user_info = jwt_decode(token);
        Api.put(`/admin/edit_menu/${id}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/menus";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const groupsListByMenuId = (menu_id: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        //if condition to prevent the Api hit without id
        if (menu_id === "") {
            dispatch({
                type: GROUPS_LIST,
                payload: ""
            })
        } else {
            Api.get(`/admin/menu/groups/${menu_id}`, {
                headers: { 'Authorization': 'Bearer ' + token }
            })
                .then((response) => {
                    if (response.data.success) {
                        dispatch({
                            type: GROUPS_LIST,
                            payload: response.data.successResponse
                        })
                    }
                }).catch(err => {
                    if (err.response) {
                        dispatch({
                            type: GROUPS_LIST,
                            payload: ""
                        })
                        // console.log(err.response.data.message)
                    } else {
                        alert(err.message)
                    }
                });
        }
    }
}

export const syncMenuAggregator = (data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        data.user_info = jwt_decode(token);
        Api.post('/admin/sync_menu_aggregator', data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    toast.info(response.data.successResponse,{position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 5000 })
                }
                setTimeout(()=>{
                    window.location.href = "/menus"
                },5000)
            }).catch(err => {
                if (err.response) {
                    toast.error(err.response.data.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    toast.error(err.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });

                }
            });
    }
}

export const publishMenu = (id: any, data: any, menu_name: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        data.menu_name = menu_name;
        data.user_info = jwt_decode(token);
        Api.put(`/admin/menu_publish/${id}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    toast.info(response.data.successResponse,{position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 2000 })
                    setTimeout(()=>{
                        window.location.href = "/menus"
                    },2000)
                } else {
                    toast.error(response.data.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                }
            }).catch(err => {
                if (err.response) {
                    console.log("err",err)
                    toast.error(err.response.data.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    // alert(err.response.data.message)
                } else {
                    // alert(err.message)
                    toast.error(err.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });

                }
            });
    }
}
export const publishLater = (id: any, data: any, menu_name: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        data.menu_name = menu_name;
        data.user_info = jwt_decode(token);
        Api.put(`/admin/publish_menu_future/${id}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/menus"
                }
            }).catch(err => {
                if (err.response) {
                    alert(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const groupsList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/groups', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GROUPS_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const groupsListFilter = (menu_type:any,brand_id?:any) => {
    return function (dispatch: any) {
        if(menu_type=='') {
            dispatch({
                type: GROUPS_LIST,
                payload: []
            })
        } else {
            let token: any = sessionStorage.getItem('token');
        Api.get('/admin/groups/' + menu_type + '/' + brand_id, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GROUPS_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                dispatch({
                    type: GROUPS_LIST,
                    payload: []
                })
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
        }
        
    }
}

export const groupsOptions = (brand_id?:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/master_groups_options/'+brand_id, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GROUPS_LIST,
                        payload: response.data.successResponse
                    })
                    dispatch({
                        type: GROUP_OPTIONS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const groupsListForMultiSelect = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/groups_options', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GROUP_OPTIONS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const groupsEditOptions = (type:any,brand_id?:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/groups-options/'+type+"/"+brand_id, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GROUPS_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const itemsListForMultiSelect = (type?:any,brand_id?:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/admin/getAllActiveItems/${type}/${brand_id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    let updatedData:any =  [];
                    response.data.successResponse.map((item:any)=>{
                        item.value = parseInt(item.value)
                        updatedData.push(item)
                    })
                    dispatch({
                        type: ACTIVE_ITEMS_OPTIONS,
                        payload: updatedData
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const storesListForMultiSelect = (brand_id?:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/storesListForMenu/'+brand_id, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: STORES_OPTIONS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}


export const storesListForMultiSelectInMenu = (brand_id?:any, aggregator_id?:any ) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/storesListForMenuHungerstation/'+brand_id+'/'+aggregator_id, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: STORES_OPTIONS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const storesListForMultiSelectByBrand = (brand_id:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/storesListForMenu/'+brand_id, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: STORES_OPTIONS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const addGroup = (formData: any,handleState?:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.post('/admin/add_group', formData, {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "multipart/form-data"
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/groups";
                }
            }).catch(err => {
                handleState(false)
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            if (err.response.data.message.indexOf("pos_code") > -1) {
                                error = 'POS code already exist';
                            } else {
                                error = 'EPR Id already exist'
                            }
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}

export const editSubGroup = (id:any,type:any,formData: any,handleState?:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.put(`/admin/edit_subgroup/${id}/${type}`, formData, {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "multipart/form-data"
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/sub-groups";
                }
            }).catch(err => {
                handleState(false)
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            if (err.response.data.message.indexOf("pos_code") > -1) {
                                error = 'POS code already exist';
                            } else {
                                error = 'EPR Id already exist'
                            }
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}

export const addSubGroup = (formData: any,handleState?:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.post('/admin/add_subgroup', formData, {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "multipart/form-data"
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/sub-groups";
                }
            }).catch(err => {
                handleState(false)
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            if (err.response.data.message.indexOf("pos_code") > -1) {
                                error = 'POS code already exist';
                            } else {
                                error = 'EPR Id already exist'
                            }
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const getGroup = (id: number) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/admin/group/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GET_GROUP,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const getGroupByaggregator = (id: number,menu_type:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/admin/group/${id}/${menu_type}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GET_GROUP,
                        payload: response.data.successResponse
                    })
                    dispatch(editMenusOptions(menu_type,response.data.successResponse.menu_id.brand_id.brand_id))
                    dispatch(storesListForMultiSelect(response.data.successResponse.menu_id.brand_id.brand_id))
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const editGroup = (id: any, formData: any,menu_type:any,handleState?:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.put(`/admin/edit_group/${id}/${menu_type}`, formData, {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "multipart/form-data"
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/groups";
                }
            }).catch(err => {
                handleState(false)
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            if (err.response.data.message.indexOf("pos_code") > -1) {
                                error = 'POS code already exist';
                            } else {
                                error = 'EPR Id already exist'
                            }
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const blockunblockgroup = (id: any, isActive: any, group_name: any,menu_type:any) => {
    return function (dispatch: any) {
        let is_active: any;
        if (isActive === 0) {
            is_active = 1
        } else if (isActive === 1) {
            is_active = 0
        }
        let token: any = sessionStorage.getItem('token');
        let data = {
            is_active: is_active,
            group_name:group_name,
            user_info: jwt_decode(token),
            menu_type:menu_type
        }
        Api.put(`/admin/group_block_unblock/${id}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/groups"
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const itemsListByGroupId = (group_id: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        if (group_id === "") {
            dispatch({
                type: ITEMS_LIST,
                payload: ""
            })
        } else {
            Api.get(`/admin/group/items/${group_id}`, {
                headers: { 'Authorization': 'Bearer ' + token }
            })
                .then((response) => {
                    if (response.data.success) {
                        dispatch({
                            type: ITEMS_LIST,
                            payload: response.data.successResponse
                        })
                    }
                }).catch(err => {
                    if (err.response) {
                        dispatch({
                            type: ITEMS_LIST,
                            payload: ""
                        })
                        // console.log(err.response.data.message)
                    } else {
                        alert(err.message)
                    }
                });
        }
    }
}
export const comboChoiceItemsByGroupId = (group_id: any, index: any,menu_type:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        if (group_id === "") {
            dispatch({
                type: COMBO_CHOICE_ITEMS,
                choiceIndex: "",
                payload: []
            })
        } else {
            Api.get(`/admin/combo_choice_items/${group_id}/${menu_type}`, {
                headers: { 'Authorization': 'Bearer ' + token }
            })
                .then((response) => {
                    if (response.data.success) {
                        dispatch({
                            type: COMBO_CHOICE_ITEMS,
                            choiceIndex: index,
                            payload: response.data.successResponse
                        })
                    }
                }).catch(err => {
                    if (err.response) {
                        dispatch({
                            type: COMBO_CHOICE_ITEMS,
                            choiceIndex: index,
                            payload: []
                        })
                        // console.log(err.response.data.message)
                    } else {
                        alert(err.message)
                    }
                });
        }
    }
}
export const itemsList = (type:any,brand_id?:any) => {
    return function (dispatch: any) {
        if(type == '') {
            dispatch({
                type: ITEMS_LIST,
                payload: []
            })
        } else {
            let token: any = sessionStorage.getItem('token');
            Api.get('/admin/menu_items/'+type + '/' + brand_id, {
                headers: { 'Authorization': 'Bearer ' + token }
            })
                .then((response) => {
                    if (response.data.success) {
                        dispatch({
                            type: ITEMS_LIST,
                            payload: response.data.successResponse
                        })
                    }
                }).catch(err => {
                    dispatch({
                        type: ITEMS_LIST,
                        payload: []
                    })
                    if (err.response) {
                        console.log(err.response.data.message)
                    } else {
                        alert(err.message)
                    }
                });
        }
        
    }
}
export const addItems = (formData: any,handleState?:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        formData.append('user_info',JSON.stringify(jwt_decode(token)))
        Api.post('/admin/add_menu_item', formData, {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "multipart/form-data"
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/menu-items"
                }
            }).catch(err => {
                handleState(false)
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            if (err.response.data.message.indexOf("pos_code") > -1) {
                                error = 'POS code already exist';
                            } else {
                                error = 'ERP Id already exist';
                            }
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const checkDuplicatePosCode = (pos_code: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/admin/check_item_poscode/${pos_code}`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                dispatch({
                    type: CHECK_DUP_POS,
                    duplicateItemPos: false,
                })
            }).catch(err => {
                if (err.response) {
                    dispatch({
                        type: CHECK_DUP_POS,
                        duplicateItemPos: true,
                    })
                    toast.error(err.response.data.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const checkDuplicateErpId = (erp_id: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/admin/check_item_erpId/${erp_id}`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                dispatch({
                    type: CHECK_DUP_ERP,
                    duplicateItemErp: false,
                })
            }).catch(err => {
                if (err.response) {
                    dispatch({
                        type: CHECK_DUP_ERP,
                        duplicateItemErp: true,
                    })
                    toast.error(err.response.data.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const getMenuItem = (id: number,type:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/admin/menu_item/${id}/${type}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GET_ITEM,
                        sizejson: response.data.successResponse.item_size !== "" ? JSON.parse(response.data.successResponse.item_size) : "",
                        payload: response.data.successResponse
                    })
                    dispatch(subGroupsByGroupByType(response.data.successResponse.item_group_id.group_id,type,'item'))
                    dispatch(storesListForMultiSelect(response.data.successResponse.item_group_id.menu_id.brand_id.brand_id))
                    dispatch(modGroupListByAggregator(type,response.data.successResponse.item_group_id.menu_id.brand_id.brand_id))
                    dispatch(menusList(type,response.data.successResponse.item_group_id.menu_id.brand_id.brand_id))
                    dispatch(groupsEditOptions(type,response.data.successResponse.item_group_id.menu_id.brand_id.brand_id))
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const blockunblockitem = (id: any, isActive: any, itemName: any,type:any) => {
    return function (dispatch: any) {
        let is_active: any;
        if (isActive === 0) {
            is_active = 1
        } else if (isActive === 1) {
            is_active = 0
        }
        let token: any = sessionStorage.getItem('token');
        let data = {
            is_active: is_active,
            item_ame:itemName,
            user_info: jwt_decode(token)
        }
        Api.put(`/admin/item_block_unblock/${id}/${type}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/menu-items"
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}


export const updateItemAvailability = (id: any, type: any, availability: any, date:any, store:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        let data = {
            dateToAvailable: date,
            hs_branch_code:store,            
        }
        Api.post(`/menu/aggregator/update_item_availability/${id}/${type}/${availability}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        }).then((response) => {
            if (response.data.success) {
                toast.success(response.data.successResponse,{position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 2000 })
                setTimeout(()=>{
                    window.location.href = "/branchmenu"
                },3000)  
            }
        }).catch(err => {
            if (err.response) {
                toast.error(err.response.data.message,{position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 2000 })
            } else {
                toast.error("Some Error",{position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 2000 })
            }
        });
    }
}

export const editItem = (id: any, formData: any,type:any,handleState?:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        formData.append('user_info',JSON.stringify(jwt_decode(token)))
        Api.put(`/admin/edit_menu_item/${id}/${type}`, formData, {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "multipart/form-data"
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/menu-items";
                }
            }).catch(err => {
                handleState(false)
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            if (err.response.data.message.indexOf("pos_code") > -1) {
                                error = 'POS code already exist';
                            } else {
                                error = 'ERP Id already exist';
                            }
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const combosList = (type:any,brand_id?:any) => {
    return function (dispatch: any) {
        if(type == '' ) {
            dispatch({
                type: COMBOS_LIST,
                payload: []
            })
        } else {
            let token: any = sessionStorage.getItem('token');
            Api.get(`/admin/combos/${type}/${brand_id}` , {
                headers: { 'Authorization': 'Bearer ' + token }
            })
                .then((response) => {
                    if (response.data.success) {
                        dispatch({
                            type: COMBOS_LIST,
                            payload: response.data.successResponse
                        })
                    }
                }).catch(err => {
                    dispatch({
                        type: COMBOS_LIST,
                        payload: []
                    })
                    if (err.response) {
                        dispatch({
                            type: COMBOS_LIST,
                            payload: []
                        })
                        console.log(err.response.data.message)
                    } else {
                        alert(err.message)
                    }
                });
        }
        
    }
}
export const groupedItemsList = (menu_id: any,type:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        if (menu_id === "") {
            dispatch({
                type: COMBO_OPTIONS,
                payload: ""
            })
        } else {
            Api.get(`/admin/combo_options/${menu_id}/${type}`, {
                headers: { 'Authorization': 'Bearer ' + token }
            })
                .then((response) => {
                    if (response.data.success) {
                        dispatch({
                            type: COMBO_OPTIONS,
                            payload: response.data.successResponse
                        })
                    }
                }).catch(err => {
                    if (err.response) {
                        console.log(err.response.data.message)
                    } else {
                        alert(err.message)
                    }
                });
        }
    }
}
export const addCombo = (formData: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.post('/admin/add_combo', formData, {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "multipart/form-data"
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/combos"
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            if (err.response.data.message.indexOf("pos_code") > -1) {
                                error = 'POS code already exist';
                            } else {
                                error = 'ERP Id already exist';
                            }
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}

export const getCombo = (id: number,type:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/admin/combo/${id}/${type}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch(subGroupsByGroup(response.data.successResponse.group_id.group_id,'combo'))
                    dispatch({
                        type: GET_COMBO,
                        combojson: IsJsonString(response.data.successResponse.combo_ingredients) ? JSON.parse(response.data.successResponse.combo_ingredients) : [
                            {
                                id: "",
                                itemName: "",
                                poscode: "",
                                choice_group_name: "",
                                groupname: "",
                                groupposcode: "",
                                size: "",
                                quantity: "",
                                limit: "",
                                itemsize: []
                            }
                        ],
                        comboChoices: IsJsonString(response.data.successResponse.comboChoices) ? JSON.parse(response.data.successResponse.comboChoices) : [
                            {
                                group_id: "",
                                // group_erp_id: "",
                                group_name: "",
                                menu_item_id: "",
                                choicesItemOptions: [],
                                size: "",
                                itemsize: []
                            }
                        ],
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const blockunblockcombo = (id: any, isActive: any, comboName: any,type:any) => {
    return function (dispatch: any) {
        let is_active: any;
        if (isActive === 0) {
            is_active = 1
        } else if (isActive === 1) {
            is_active = 0
        }
        let token: any = sessionStorage.getItem('token');
        let data = {
            is_active: is_active,
            combo_name:comboName,
            user_info: jwt_decode(token)
        }
        Api.put(`/admin/combo_block_unblock/${id}/${type}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch(combosList(type))
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const editCombo = (id: any, formData: any,type:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.put(`/admin/edit_combo/${id}/${type}`, formData, {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "multipart/form-data"
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/combos";
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            if (err.response.data.message.indexOf("pos_code") > -1) {
                                error = 'POS code already exist';
                            } else {
                                error = 'ERP Id already exist';
                            }
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const modGroupList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/mod_groups', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: MOD_GROUP_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const modGroupListByAggregator = (menu_type:any,brand_id?:any) => {
    return function (dispatch: any) {
        if(menu_type == '') {
            dispatch({
                type: MOD_GROUP_LIST,
                payload: []
            })
        } else {
            let token: any = sessionStorage.getItem('token');
            Api.get('/admin/mod_groups/' + menu_type + '/' + brand_id, {
                headers: { 'Authorization': 'Bearer ' + token }
            })
                .then((response) => {
                    if (response.data.success) {
                        dispatch({
                            type: MOD_GROUP_LIST,
                            payload: response.data.successResponse
                        })
                    }
                }).catch(err => {
                    dispatch({
                        type: MOD_GROUP_LIST,
                        payload: []
                    })
                    if (err.response) {
                        console.log(err.response.data.message)
                    } else {
                        alert(err.message)
                    }
                });
        }
        
    }
}

export const modifierAsItems = (menu_type:any,brand_id?:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/items_for_modifiers/' + menu_type + "/" + brand_id, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: MODIFIER_ITEMS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const addModGroup = (data: any,handleState?:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        data.user_info = jwt_decode(token);
        Api.post('/admin/add_mod_group', data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/mod-groups";
                }
            }).catch(err => {
                handleState(false)
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            if (err.response.data.message.indexOf("pos_code") > -1) {
                                error = 'POS code already exist';
                            }
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const getModGroup = (id: number,menu_type:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/admin/mod_group/${id}/${menu_type}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GET_MOD_GROUP,
                        payload: response.data.successResponse
                    })
                    dispatch(itemsListForMultiSelect(menu_type,response.data.successResponse.brand_id))
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const editModGroup = (id: any, data: any,type:any,handleState:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        data.user_info = jwt_decode(token);
        Api.put(`/admin/edit_mod_group/${id}/${type}`, data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/mod-groups";
                }
            }).catch(err => {
                handleState(false)
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            if (err.response.data.message.indexOf("pos_code") > -1) {
                                error = 'POS code already exist';
                            }
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0];
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const blockunblockModifierGroup = (id: any, isActive: any, history: any, modGroupName: any,type:any) => {
    return function (dispatch: any) {
        let is_active: any;
        if (isActive === 0) {
            is_active = 1
        } else if (isActive === 1) {
            is_active = 0
        }
        let token: any = sessionStorage.getItem('token');
        let data = {
            is_active: is_active,
            mod_group_name:modGroupName,
            user_info: jwt_decode(token)
        }
        Api.put(`/admin/mod_group_block_unblock/${id}/${type}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    history.push("/mod-groups")
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const modifierListByModGroupId = (id: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/admin/modifiers/${id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: MOD_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    dispatch({
                        type: MOD_LIST,
                        payload: ""
                    })
                } else {
                    alert(err.message)
                }
            });
    }
}
export const modifierList = (type:any,brand_id?:any) => {
    return function (dispatch: any) {
        if(type == '') {
            dispatch({
                type: MOD_LIST,
                payload: []
            })
        } else {
            let token: any = sessionStorage.getItem('token');
            Api.get('/admin/modifiers_data/'+type + '/' + brand_id, {
                headers: { 'Authorization': 'Bearer ' + token }
            })
                .then((response) => {
                    if (response.data.success) {
                        dispatch({
                            type: MOD_LIST,
                            payload: response.data.successResponse
                        })
                    }
                }).catch(err => {
                    dispatch({
                        type: MOD_LIST,
                        payload: []
                    })
                    if (err.response) {
                        console.log(err.response.data.message)
                    } else {
                        alert(err.message)
                    }
                });
        }
        
    }
}
export const addModifier = (formData: any,handleState?:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        formData.append('user_info',JSON.stringify(jwt_decode(token)))
        Api.post('/admin/add_modifier', formData, {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "multipart/form-data"
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/modifiers";
                }
            }).catch(err => {
                handleState(false)
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            if (err.response.data.message.indexOf("pos_code") > -1) {
                                error = 'POS code already exist';
                            } else {
                                error = 'ERP Id already exist';
                            }
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0].replace(/_/g, " ");
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const getModifier = (id: number,type:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/admin/modifier/${id}/${type}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: GET_MOD,
                        payload: response.data.successResponse
                    })
                    dispatch(modGroupsList(type,response.data.successResponse.brand_id))
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const editModifier = (id: any, formData: any,type:any,handleState?:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        formData.append('user_info',JSON.stringify(jwt_decode(token)))
        Api.put(`/admin/edit_modifier/${id}/${type}`, formData, {
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "multipart/form-data"
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/modifiers";
                }
            }).catch(err => {
                handleState(false)
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else if (err.response.data.code === "ER_DUP_ENTRY") {
                            if (err.response.data.message.indexOf("pos_code") > -1) {
                                error = 'POS code already exist';
                            } else {
                                error = 'ERP Id already exist';
                            }
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0];
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const modGroupsList = (type?:any,brand_id?: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/admin/activeModGroups/${type}/${brand_id}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: MOD_GROUP_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const deleteModifier = (id: number, modName: any,type:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        let data = {
            modifier_name: modName,
            user_info: jwt_decode(token)
        }
        Api.post(`/admin/del_modifier/${id}/${type}`,data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.href = "/modifiers";
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const syncBranchMenuWithStore = (store_id: any,menu_type:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        let data = {
            "store_id":store_id, "aggregator_id":menu_type,"brand_id" :1
        }
        Api.post(`/admin/sync_aggregator_menu_with_store`,data, {
            headers: { 'Authorization': 'Bearer ' + token }
        }).then((response)=>{
            if (response.data.success) {
                toast.success(response.data.successResponse, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                setTimeout(() => {
                   window.location.href = "/branchmenu";
                }, 2000);
            }
        })
        .catch((err)=>{
            if (err.response) {
                toast.error("Failed", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                setTimeout(() => {
                   window.location.href = "/branchmenu"
                }, 2000);            
            }
        })
    }
}
export const getAllMenuList = (store_id: any,menu_type:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get(`/store/getAllMenu/${store_id}/${menu_type}`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    console.log("response.data.successResponse", response.data.successResponse)
                    let groupsData: any = [];
                    let itemsData: any = [];
                    let comboData: any = [];
                    let modifiersData: any = [];
                    let modgroupsData: any = [];
                    response.data.successResponse.forEach((obj: any) => {
                        if (obj.group_id) {
                            groupsData.push(obj)
                        } else if (obj.menu_item_id) {
                            itemsData.push(obj)
                        } else if (obj.combo_id) {
                            comboData.push(obj)
                        }
                        else if (obj.modifier_group_id) {
                            modgroupsData.push(obj)
                        }
                        else if (obj.modifier_id) {
                            modifiersData.push(obj)
                        }
                    })
                    dispatch(searchMenuItem("", itemsData));
                    dispatch(searchGroup("", groupsData));
                    dispatch(searchCombos("", comboData));
                    dispatch(searchModifierGroups("", modgroupsData));
                    dispatch(searchModifiers("", modifiersData));
                    dispatch({
                        type: GET_BRANCH_MENU,
                        groupsData: groupsData,
                        itemsData: itemsData,
                        combosData: comboData,
                        modifiersData:modifiersData,
                        modgroupsData:modgroupsData
                    })
                }
            }).catch(err => {
                if (err.response) {
                    dispatch(searchMenuItem("", []));
                    dispatch(searchGroup("", []));
                    dispatch(searchCombos("", []));
                    dispatch(searchModifierGroups("", []));
                    dispatch(searchModifiers("", []));
                } else {
                    dispatch(searchMenuItem("", []));
                    dispatch(searchGroup("", []));
                    dispatch(searchCombos("", []));
                    dispatch(searchModifierGroups("", []));
                    dispatch(searchModifiers("", []));
                    alert(err.message)
                }
            });
    }
}

export const updateItemStatus = (data: any, history: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        var store: any = jwt_decode(token);
        data.user_id = store.user_id;
        Api.put(`/store/updateItemStatus/${data.store_id}`, data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    toast.info("Status changed successfully", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    dispatch(getAllMenuList(data.store_id,data.menu_type))
                }
            }).catch(err => {
                if (err.response) {
                    toast.error(err.response.data.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}

export const statusChangeReasons = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.post('/reasons/reasonByType', {
            type: 'menu'
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: STATUS_CHANGE_REASONS,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const ingList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/ingredients', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: ING_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const addIng = (data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.post('/admin/add_ingredient', data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.reload();
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        error = err.response.data.message;
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0];
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}
export const ingCatList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/ing_category', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: ING_CAT_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const ingUomList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/ing_uom', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: ING_UOM_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
//recipes

export const recipeList = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/recipes', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    dispatch({
                        type: REC_LIST,
                        payload: response.data.successResponse
                    })
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}
export const addRecipe = (data: any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.post('/admin/add_recipe', data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.data.success) {
                    window.location.reload();
                }
            }).catch(err => {
                if (err.response) {
                    let error;
                    if (typeof (err.response.data.message) === "string") {
                        if (err.response.data.code === "ER_NO_REFERENCED_ROW_2") {
                            error = "Please fill in all fields"
                        } else {
                            error = err.response.data.message;
                        }
                    } else if (typeof (err.response.data.message) === "object") {
                        error = err.response.data.message[0];
                    }
                    toast.error(error, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}

export const searchGroup = (value: any, groupsData: any) => {
    return function (dispatch: any) {
        const groups = groupsData.filter((item: any) => item.group_id.group_name.toLowerCase().includes(value.toLowerCase()));
        dispatch({
            type: SEARCH_GROUPS,
            searchGroupData: value == "" ? groupsData : groups,
        })
    }
}

export const searchMenuItem = (value: any, menuItemData: any) => {
    return function (dispatch: any) {
        const items = menuItemData.filter((item: any) => item.menu_item_id.item_name.toLowerCase().includes(value.toLowerCase()));
        dispatch({
            type: SEARCH_MENU_ITEM,
            searchItemData: value == "" ? menuItemData : items,
        })
    }
}
export const searchCombos = (value: any, combosData: any) => {
    return function (dispatch: any) {
        const combos = combosData.filter((item: any) => item.combo_id.combo_name.toLowerCase().includes(value.toLowerCase()));
        dispatch({
            type: SEARCH_COMBOS,
            searchComboData: value == "" ? combosData : combos,
        })
    }
}

export const  searchModifierGroups = (value: any, modgroupsData: any) => {
    return function (dispatch: any) {
        const modgroups = modgroupsData.filter((item: any) => item.modifier_group_id.mod_group_name.toLowerCase().includes(value.toLowerCase()));
        dispatch({
            type: SEARCH_MODIFIER_GROUPS,
            searchmodgroupsData: value == "" ? modgroupsData : modgroups,
        })
    }
}
export const  searchModifiers = (value: any, modifiersData: any) => {
    return function (dispatch: any) {
        const modifiers = modifiersData.filter((item: any) => item.modifier_id?.modifier_name?.toLowerCase().includes(value.toLowerCase()));
        dispatch({  
            type: SEARCH_MODIFIERS,
            searchmodifiersData: value == "" ? modifiersData : modifiers,
        })
    }
}
// api to get all the ordering modes from database
export const OrderingModes = () => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.get('/admin/order_modes', {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                let order_modes_arr: any = [];
                let successResponse = response.data.successResponse;
                for(let i=0; i<successResponse.length; i++){
                    let obj = {
                        label: successResponse[i].name,
                        value: successResponse[i].id
                    }
                    order_modes_arr.push(obj)
                }
                if (response.data.success) {
                    dispatch({
                        type: ORDER_MODES,
                        payload: order_modes_arr
                    })
                }
            }).catch(err => {
                dispatch({
                    type: ORDER_MODES,
                    payload: []
                })
                if (err.response) {
                    console.log(err.response.data.message)
                } else {
                    alert(err.message)
                }
            });
    }
}

export const cloneMenu = (data:any) => {
    return function (dispatch: any) {
        let token: any = sessionStorage.getItem('token');
        Api.post('/admin/clone_menu',data, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((response) => {
                if (response.data.success) {
                    toast.info(response.data.successResponse, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                    setTimeout(()=>{
                        // window.location.href = '/menus'
                    },3000)
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response.data.message)
                    toast.error(err.response.data.message, { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
                } else {
                    alert(err.message)
                }
            });
    }
}