import React, { Component } from 'react';
import { AddCouponProps, AddCouponState } from '../../interfaces/coupon'
import { connect } from 'react-redux'
import { addCoupon, brandsList, menusList, groupsList, logoutUser, itemsListForMultiSelect } from '../../redux'
import { Link, Redirect } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import CheckChanges from '../../components/confirmOnLeave'
import Select from 'react-select';
class AddCoupon extends Component<AddCouponProps, AddCouponState> {
    constructor(props: any) {
        super(props);
        this.state = {
            couponname: "",
            coupondesc: "",
            couponvalue: "",
            expiryDate: "",
            percent: "",
            mode: "all",
            // channel: "delivery",
            type: "menu",
            discountType: "value",
            limit: "1",
            type_id: "",
            multiJson: []
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCouponType = this.handleCouponType.bind(this);
        this.handleDiscountType = this.handleDiscountType.bind(this);
    }
    componentDidMount() {
        document.title = "SimpleXSync | Coupons"
        this.props.itemsListForMultiSelect();
        this.props.menusList();
        this.props.groupsList();
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }
    handleCouponType(event: { target: { name: any; value: any; }; }) {
        this.setState({
            type_id: "",
            [event.target.name]: event.target.value,
        });
    }
    handleItemsInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ multiJson: e });
        }
    }
    handleDiscountType(event: { target: { name: any; value: any; }; }) {
        this.setState({
            percent: "",
            couponvalue: "",
            [event.target.name]: event.target.value,
        });
    }

    blockInvalidChar = (e: any) =>
        ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

    isCouponReady = () => {
        const { couponname, couponvalue, expiryDate, multiJson, mode, type, discountType, limit, type_id, percent } = this.state
        if (type == "menu" || type == "group") {
            return (couponname !== "" && expiryDate !== "" && mode !== "" && type !== "" && discountType !== ""
                && limit !== "" && type_id !== "" && (percent !== "" || couponvalue !== ""));
        } else if (type == "item") {
            return (couponname !== "" && expiryDate !== "" && mode !== "" && type !== "" && discountType !== ""
                && limit !== "" && multiJson.length > 0 && (percent !== "" || couponvalue !== ""));
        }
    }
    handleSaveBtnClick = (event: any) => {
        let { couponname, coupondesc, couponvalue, expiryDate, mode, type, limit, type_id, percent, multiJson } = this.state;
        let data: any = {
            coupon_name: couponname,
            coupon_description: coupondesc,
            coupon_value: couponvalue,
            expire_date: expiryDate,
            mode: mode,
            // channel: channel,
            type: type,
            limit: limit,
            percent: percent,
            is_archive: 0
        }
        if (type == "menu" || type == "group") {
            data.type_id = type_id;
        } else if (type == "item") {
            data.items_json = JSON.stringify(multiJson);
        }
        this.props.addCoupon(data);
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let msg;
        let messcolor;
        const { isInserted, message, menus, groups, items } = this.props;

        if (!isInserted) {
            msg = message;
            messcolor = 'red'
        }
        return (
            <div className="page">
                <CheckChanges path="/add-coupon" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Compaigns Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/marketing/0" className="text-primary">Compaigns</Link></li>
                                <li className="breadcrumb-item active">Add Coupon</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Coupon name <span className="text-danger">*</span></label>
                                                            <input id="couponname" type="text" name="couponname" required data-msg="Please enter Coupon Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Description</label>
                                                            <input id="coupondesc" type="text" name="coupondesc" required data-msg="Please enter Description" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Coupon Type <span className="text-danger">*</span></label>
                                                            <select name="type" className="form-control text-capitalize mt-2" required data-msg="Please select Type" onChange={this.handleCouponType}>
                                                                <option value='menu'>Menu</option>
                                                                <option value='group'>Group</option>
                                                                <option value='item'>Item</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    {this.state.type === "menu" &&
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Select Menu <span className="text-danger">*</span></label>
                                                                <select name="type_id" className="form-control text-capitalize mt-2" required data-msg="Please select Group" onChange={this.handleInputChange}>
                                                                    <option>Select Menu</option>
                                                                    {menus &&
                                                                        menus.map((menu: any, index: any) => (
                                                                            <option key={index} value={menu.menu_id}>{menu.menu_name}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                    }

                                                    {this.state.type === "group" &&
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Select Group <span className="text-danger">*</span></label>
                                                                <select name="type_id" className="form-control text-capitalize mt-2" required data-msg="Please select Group" onChange={this.handleInputChange}>
                                                                    <option>Select Group</option>
                                                                    {groups &&
                                                                        groups.map((group: any, index: any) => (
                                                                            <option key={index} value={group.group_id}>{group.group_name}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                    }
                                                    {this.state.type === "item" &&
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Select Items <span className="text-danger">*</span></label>
                                                                <Select
                                                                    isMulti
                                                                    name="items"
                                                                    options={items}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleItemsInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="row">
                                                    {/* <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Ordering Channel <span className="text-danger">*</span></label>
                                                            <select name="channel" className="form-control text-capitalize mt-2" required data-msg="Please select Type" onChange={this.handleInputChange}>
                                                                <option value='delivery'>Delivery</option>
                                                                <option value='pickup'>Pick Up</option>
                                                            </select>
                                                        </div>
                                                    </div> */}
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Ordering Mode <span className="text-danger">*</span></label>
                                                            <select name="mode" className="form-control text-capitalize mt-2" required data-msg="Please select Type" onChange={this.handleInputChange}>
                                                                <option value='all'>All</option>
                                                                <option value='online'>Online</option>
                                                                <option value='callcenter'>Call Center</option>
                                                                <option value='mobile'>Mobile</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Discount Type <span className="text-danger">*</span></label>
                                                            <select name="discountType" className="form-control text-capitalize mt-2" required data-msg="Please select Type" onChange={this.handleDiscountType}>
                                                                <option value='value'>Value</option>
                                                                <option value='percentage'>Percentage</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    {this.state.discountType === "percentage" &&
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Coupon Percentage <span className="text-danger">*</span></label>
                                                                <input id="percent" type="text" name="percent" min="1" onKeyDown={this.blockInvalidChar} required data-msg="Please enter Coupon Percentage" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    }
                                                    {this.state.discountType === "value" &&
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Coupon value <span className="text-danger">*</span></label>
                                                                <input id="couponvalue" type="text" name="couponvalue" min="1" onKeyDown={this.blockInvalidChar} required data-msg="Please enter Coupon Value" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>

                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Coupon Limit <span className="text-danger">*</span></label>
                                                            <input id="limit" type="number" name="limit" defaultValue='1' min="1" required data-msg="Please enter Limit" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Valid till <span className="text-danger">*</span></label>
                                                            <input id="expiryDate" type="datetime-local" name="expiryDate" required data-msg="Please enter Expire Date" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    <button className='btn btn-primary' disabled={!this.isCouponReady()} onClick={this.handleSaveBtnClick}>Save</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div >
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        brands: state.store.brands,
        menus: state.menu.menus,
        groups: state.menu.groups,
        items: state.menu.allActiveItems,
        message: state.coupon.message,
        isInserted: state.coupon.isInserted
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        addCoupon: function (data: any) {
            dispatch(addCoupon(data));
        },
        brandsList: function () {
            dispatch(brandsList())
        },
        menusList: function () {
            dispatch(menusList())
        },
        groupsList: function () {
            dispatch(groupsList())
        },
        itemsListForMultiSelect: function () {
            dispatch(itemsListForMultiSelect())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddCoupon);