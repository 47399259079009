import React, { Component } from 'react';
import { AddStoreProps, AddStoreState } from '../../interfaces/store'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { addStore, brandsList, channelList, storeTypeList, areasList, countryList, statesList, addStates, storesList, tradeZoneList } from '../../redux'
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import Map from './map';
import CheckChanges from '../../components/confirmOnLeave'
import { Link, Redirect } from 'react-router-dom';
import { businessTypeList } from '../../redux/actions/settingsAction';
import Select from 'react-select';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class AddStore extends Component<AddStoreProps, AddStoreState> {
    salesChannelDropDown: any;
    constructor(props: any) {
        super(props);
        this.state = {
            storename: "",
            storeemail: "",
            escalationemail: "",
            contact1: "",
            contact2: "",
            contact3: "",
            brand: "",
            saleschannel: "",
            storetype: "",
            area: "",
            state: "",
            state_id: "",
            state_name: "",
            city: "",
            country: "",
            country_code: "",
            country_id: "",
            address: "",
            storeopen: "",
            storeclose: "",
            tradezonename: "",
            ntn: "",
            expected_delivery_time: 0,
            kmlFile: null,
            kml: "",
            lat: 0,
            lng: 0,
            newlat: 0,
            newlng: 0,
            radius: 50,
            businessType: "",
            timeByDays: false,
            cloudKitchen: "Disabled",
            branchCode: "",
            hungerstation_branchCode: "",
            hungerstation_restaurant_id:"",
            talabat_branchCode:"",
            fiscal_pos_code: "",
            trade_zone_id: "",
            backupStore: [
                {
                    storeId: "",
                    backUpStoreId: "",
                    priority: ""
                }
            ],
            tradeAreas: [
                {
                    tradeArea: "",
                    tradeZone: ""
                }
            ],
            mondayTime: {
                day: 'Monday',
                opening: '',
                closing: ''
            },
            tuesdayTime: {
                day: 'Tuesday',
                opening: '',
                closing: ''
            },
            wednesdayTime: {
                day: 'Wednesday',
                opening: '',
                closing: ''
            },
            thursdayTime: {
                day: 'Thursday',
                opening: '',
                closing: ''
            },
            fridayTime: {
                day: 'Friday',
                opening: '',
                closing: ''
            },
            saturdayTime: {
                day: 'Saturday',
                opening: '',
                closing: ''
            },
            sundayTime: {
                day: 'Sunday',
                opening: '',
                closing: ''
            }
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        document.title = "SimplexCMS | Stores"
        this.props.brandsList();
        this.props.channelsList();
        this.props.storeTypeList();
        this.props.areasList();
        this.props.countryList();
        this.props.businessTypeList();
        this.props.storesList();
        this.props.tradeZoneList();
    }

    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    blockInvalidChar = (e: any) =>
        ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

    dayOpeningTime = (e: any) => {
        const { name, value } = e.target;
        if (name == "mondayTime") {
            const list: any = this.state.mondayTime;
            list.opening = value;
            this.setState({ mondayTime: list });

        } else if (name == "tuesdayTime") {
            const list: any = this.state.tuesdayTime;
            list.opening = value;
            this.setState({ tuesdayTime: list });

        } else if (name == "wednesdayTime") {
            const list: any = this.state.wednesdayTime;
            list.opening = value;
            this.setState({ wednesdayTime: list });

        } else if (name == "thursdayTime") {
            const list: any = this.state.thursdayTime;
            list.opening = value;
            this.setState({ thursdayTime: list });

        } else if (name == "fridayTime") {
            const list: any = this.state.fridayTime;
            list.opening = value;
            this.setState({ fridayTime: list });

        } else if (name == "saturdayTime") {
            const list: any = this.state.saturdayTime;
            list.opening = value;
            this.setState({ saturdayTime: list });

        } else if (name == "sundayTime") {
            const list: any = this.state.sundayTime;
            list.opening = value;
            this.setState({ sundayTime: list });
        }
    };

    dayClosingTime = (e: any) => {
        const { name, value } = e.target;
        if (name == "mondayTime") {
            const list: any = this.state.mondayTime;
            list.closing = value;
            this.setState({ mondayTime: list });

        } else if (name == "tuesdayTime") {
            const list: any = this.state.tuesdayTime;
            list.closing = value;
            this.setState({ tuesdayTime: list });

        } else if (name == "wednesdayTime") {
            const list: any = this.state.wednesdayTime;
            list.closing = value;
            this.setState({ wednesdayTime: list });

        } else if (name == "thursdayTime") {
            const list: any = this.state.thursdayTime;
            list.closing = value;
            this.setState({ thursdayTime: list });

        } else if (name == "fridayTime") {
            const list: any = this.state.fridayTime;
            list.closing = value;
            this.setState({ fridayTime: list });

        } else if (name == "saturdayTime") {
            const list: any = this.state.saturdayTime;
            list.closing = value;
            this.setState({ saturdayTime: list });

        } else if (name == "sundayTime") {
            const list: any = this.state.sundayTime;
            list.closing = value;
            this.setState({ sundayTime: list });
        }
    };

    isStoreReady = () => {
        const { trade_zone_id, storename, storeemail, tradezonename, kml, kmlFile, brand, branchCode, fiscal_pos_code, saleschannel, storetype, storeopen, expected_delivery_time,
            storeclose, cloudKitchen, businessType, timeByDays, mondayTime, tuesdayTime, wednesdayTime, thursdayTime, fridayTime, saturdayTime, sundayTime, state_id, tradeAreas } = this.state

        if (timeByDays) {
            return (trade_zone_id !== "" && storename !== "" && storeemail !== "" && state_id !== "" && branchCode !== "" && businessType !== "" && brand !== "" && saleschannel !== "" && storetype !== "" &&
                cloudKitchen !== "" && mondayTime.opening !== "" && mondayTime.closing !== "" && tuesdayTime.opening !== "" && tuesdayTime.closing !== "" && expected_delivery_time > 0
                && wednesdayTime.opening !== "" && wednesdayTime.closing !== "" && thursdayTime.opening !== "" && thursdayTime.closing !== ""
                && fridayTime.opening !== "" && fridayTime.closing !== "" && saturdayTime.opening !== "" && saturdayTime.closing !== ""
                && sundayTime.opening !== "" && sundayTime.closing !== "");
        } else {
            return (trade_zone_id !== "" && storename !== "" && businessType !== "" && state_id !== "" && branchCode !== "" && expected_delivery_time > 0 && storeemail !== "" && brand !== "" && saleschannel !== "" && storetype !== "" && storeopen !== "" && storeclose !== "" && cloudKitchen !== "");
        }

    }
    fileSelectedHandler = (e: any) => {
        this.setState({ kmlFile: e.target.files })
    }
    handleMapData = (obj: any) => {    //to Get the values from the map component and set in state
        this.setState({
            address: obj.address,
            // state: obj.state,
            city: obj.city,
            country: obj.country,
            country_code: obj.country_code,
            lat: obj.lat,
            lng: obj.lng
        })
    }
    handleRadius = (radius: any) => {    //to Get the Radius from the map component and set in state
        this.setState({
            radius: radius
        })
    }
    checkAreaNameDuplication = () => {
        const { tradeAreas } = this.state;
        var result: any = [];
        // To Remove all Duplicate objects
        var array: any = tradeAreas,
            seen = Object.create(null),
            result = array.filter((o: any) => {
                var key = ['tradeZone', 'tradeArea'].map(k => o[k]).join('|');
                if (!seen[key]) {
                    seen[key] = true;
                    return true;
                }
            });
        if (array.length !== result.length) {
            //check original array length after remove duplicates
            return true;
        } else {
            return false;
        }
    }
    handleSaveBtnClick = async (event: any) => {
        let daysTiming: any = [];
        let { trade_zone_id, storename, storeemail, escalationemail, ntn, contact1, contact2, contact3, brand, saleschannel, storetype, storeopen, storeclose, state_id, city,
            expected_delivery_time, address, lat, lng, cloudKitchen, businessType, timeByDays, backupStore, mondayTime, tuesdayTime, wednesdayTime, thursdayTime,
            fridayTime, saturdayTime, sundayTime, branchCode,hungerstation_branchCode,hungerstation_restaurant_id,
            talabat_branchCode, country_id, tradeAreas } = this.state;
        let DuplicationFlag: any = await this.checkAreaNameDuplication();
        if (DuplicationFlag) {
            toast.error("Duplicate areas found for one tradezone", { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true, autoClose: 3000 });
        } else {
            let data: any = {
                store_name: storename,
                store_email: storeemail,
                // store_tags: JSON.stringify(this.state.activeTags),
                trade_zone_id: trade_zone_id,
                escalation_email: escalationemail,
                contact1: contact1,
                contact2: contact2,
                contact3: contact3,
                branch_code: branchCode,
                hungerstation_branchCode: hungerstation_branchCode,
                hungerstation_restaurant_id:hungerstation_restaurant_id,
                talabat_branchCode:talabat_branchCode,
                // city: city,
                sales_channel_id: saleschannel,
                store_type_id: storetype,
                brand_id: brand,
                expected_delivery_time: expected_delivery_time,
                ntn_number:ntn,
                address: address,
                lat: lat,
                lng: lng,
                business_type_id: businessType,
                cloudKitchen: cloudKitchen,
                backupStore: JSON.stringify(backupStore),
                tradeAreas: JSON.stringify(tradeAreas),
                is_active: 1
            }

            if (timeByDays) {
                daysTiming.push(mondayTime);
                daysTiming.push(tuesdayTime);
                daysTiming.push(wednesdayTime);
                daysTiming.push(thursdayTime);
                daysTiming.push(fridayTime);
                daysTiming.push(saturdayTime);
                daysTiming.push(sundayTime);
                data.daysTiming = JSON.stringify(daysTiming)
            } else {
                data.store_open_time = storeopen;
                data.store_close_time = storeclose;
                data.daysTiming = '';
            }
            if (timeByDays === true) {
                data.timeByDays = 1;
            } else {
                data.timeByDays = 0;
            }
            if (country_id !== "") {
                data.country_id = country_id;
            }
            if (state_id !== "") {
                data.state_id = state_id;
            }
            this.props.addStore(data);
        }
    }

    handleTimeByDays = (event: { target: { name: any; value: any; }; }) => {
        const { timeByDays } = this.state;
        this.setState({ timeByDays: !timeByDays })
    }
    handleBackupStoreInputChange = (e: any, index: any) => {
        if (e && e.target) {
            const { name, value } = e.target;
            const list: any = this.state.backupStore;
            list[index][name] = value;
            this.setState({ backupStore: list });
        } else {
            const list: any = this.state.backupStore;
            if (e && e.value > 0) {
                list[index]["backUpStoreId"] = e.value;
            } else {
                list[index]["backUpStoreId"] = "";
            }
            this.setState({ backupStore: list });
        }
    };
    handleTradeAreaInputChange = (e: any, index: any) => {
        if (e && e.target) {
            const { name, value } = e.target;
            const list: any = this.state.tradeAreas;
            list[index][name] = value;
            this.setState({ tradeAreas: list });
        } else {
            const list: any = this.state.tradeAreas;
            if (e && e.value > 0) {
                list[index]["tradeZone"] = e.value;
            } else {
                list[index]["tradeZone"] = "";
            }
            this.setState({ tradeAreas: list });
        }
    };

    handleAddBackupStore = (i: any) => {
        const list: any = this.state.backupStore;
        if (list[i]["backUpStoreId"] == "" || list[i]["priority"] == "") {
            alert("Please fill in current item")
        } else if (i == '1') {
            alert("Sorry you can select only 2 Backup Stores")
        } else {
            const newlist: any = { backUpStoreId: "", priority: "", storeId: "" }
            const backupStr: any = this.state.backupStore.concat(newlist)
            this.setState({ backupStore: backupStr });
        }
    };

    handleAddTradeArea = (i: any) => {
        const list: any = this.state.tradeAreas;
        if (list[i]["tradeZone"] == "" || list[i]["tradeArea"] == "") {
            alert("Please fill in current item")
        } else {
            const newlist: any = { tradeZone: "", tradeArea: "" }
            const tradeArea: any = this.state.tradeAreas.concat(newlist)
            this.setState({ tradeAreas: tradeArea });
        }
    };
    handleRemoveBackupStore = (index: any) => {
        const list = this.state.backupStore;
        list.splice(index, 1);
        this.setState({ backupStore: list });
    };
    handleRemoveTradeArea = (index: any) => {
        const list = this.state.tradeAreas;
        list.splice(index, 1);
        this.setState({ tradeAreas: list });
    };

    handleBrands = (e: any, index: any) => {
        if (e && e.value > 0) {
            this.props.statesList(e.value);
            this.setState({ brand: e.value, state_id: "", state_name: "" });
        } else {
            this.setState({ brand: "", state_id: "", state_name: "" });
        }
    };
    handleTradeZones = (e: any, index: any) => {
        if (e && e.value > 0) {
            this.setState({ trade_zone_id: e.value, kml: e.kml_shape });
        } else {
            this.setState({ trade_zone_id: "", kml: "" });
        }
    };
    handleBusinessType = (e: any, index: any) => {
        if (e && e.value > 0) {
            this.setState({ businessType: e.value });
        } else {
            this.setState({ businessType: "" });
        }
    };
    handleCloudKitchen = (e: any, index: any) => {
        if (e && e.value !== "") {
            this.setState({ cloudKitchen: e.value });
        } else {
            this.setState({ cloudKitchen: "" });
        }
        this.salesChannelDropDown.select.clearValue();
    };
    handleSalesChannel = (e: any, index: any) => {
        if (e && e.value > 0) {
            this.setState({ saleschannel: e.value });
        } else {
            this.setState({ saleschannel: "" });
        }
    };
    handleStoreTypes = (e: any, index: any) => {
        if (e && e.value > 0) {
            this.setState({ storetype: e.value });
        } else {
            this.setState({ storetype: "" });
        }
    };
    handleStates = (e: any, index: any) => {
        if (e && e.value > 0) {
            this.setState({ state_id: e.value, country_id: e.country_id, state_name: e.label });
        } else {
            this.setState({ state_id: "", country_id: "", state_name: "" });
        }
    };
    blockInvalidNumberData = (e: any) => {
        var regex = new RegExp("[0-9]");
        let specialkey: any = e.keyCode;
        var key: any = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (specialkey == 8 || specialkey == 9) {
            return true;
        } else {
            if (/\S/.test(e.target.value)) {
                // string is not empty and not just whitespace
                if (!regex.test(key)) {
                    if (specialkey == 32) {
                    } else {
                        e.preventDefault()
                    }
                }
            } else {
                ((!regex.test(key) || specialkey == 32)) && e.preventDefault()
            }
        }
    }
    blockInvalidCharData = (e: any) => {
        var regex = new RegExp("[a-zA-Z]");
        let specialkey: any = e.keyCode;
        var key: any = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (specialkey == 8 || specialkey == 9) {
            return true;
        } else {
            if (/\S/.test(e.target.value)) {
                // string is not empty and not just whitespace
                if (!regex.test(key)) {
                    if (specialkey == 32) {
                    } else {
                        e.preventDefault()
                    }
                }
            } else {
                ((!regex.test(key) || specialkey == 32)) && e.preventDefault()
            }
        }
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let tokendata: any = jwt.decode(sessionStorage.token)
        let roleId = tokendata.role_id;
        let brandId = tokendata.brand_id;
        const { brands, channels, types, businessType, stores, states, tradezones } = this.props;
        const { timeByDays, cloudKitchen, backupStore, tradeAreas, state_id } = this.state;
        // Sales Channel data
        let salesChannelData;
        (cloudKitchen == 'Enabled') ?
            salesChannelData = channels.filter(function (el: any) {
                return el.sales_channel_id == 1
            }) :
            salesChannelData = channels;
        // Store Type data
        return (
            <div className="page">
                <CheckChanges path="/add-store" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header pb-1">
                            <div className="container-fluid">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h4>Stores Management</h4>
                                </div>
                            </div>
                        </header>
                        {/*  Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/stores" className="text-primary">Stores</Link></li>
                                <li className="breadcrumb-item active">Add Store</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Store Name <span className="text-danger">*</span></label>
                                                            <input id="storename" type="text" name="storename" required data-msg="Please enter Store Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Brand <span className="text-danger">*</span></label>
                                                            <Select
                                                                name="brand"
                                                                isClearable
                                                                options={roleId == 7 ? brands : brands.filter(o1 => brandId === o1.brand_id)}
                                                                className="text-capitalize select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleBrands(e, i)}
                                                            />
                                                            {/* <select name="brand" className="form-control text-capitalize mt-2" required data-msg="Please select brand" onChange={this.handleInputChange}>
                                                                <option value="">Select Brand</option>
                                                                {brands &&
                                                                    brands.map((brand, index) => (
                                                                        brand.is_active == 1 &&
                                                                        <option key={index} value={brand.brand_id}>{brand.brand_name}</option>
                                                                    ))
                                                                }
                                                            </select> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Branch Id <span className="text-danger">*</span></label>
                                                            <input id="branchCode" type="text" name="branchCode" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="form-control-label">Hungerstation Branch Id <span className="text-danger">*</span></label>
                                                            <input id="branchCode" type="text" name="hungerstation_branchCode" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="form-control-label">Hungerstation Platform Restaurant Id <span className="text-danger">*</span></label>
                                                            <input id="branchCode" type="text" name="hungerstation_restaurant_id" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="form-control-label">Talabat Branch Id <span className="text-danger">*</span></label>
                                                            <input id="branchCode" type="text" name="talabat_branchCode"  className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Business Type <span className="text-danger">*</span></label>
                                                            <Select
                                                                name="businessType"
                                                                isClearable
                                                                options={businessType}
                                                                className="text-capitalize select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleBusinessType(e, i)}
                                                            />
                                                            {/* <select name="businessType" className="form-control text-capitalize mt-2" required data-msg="Please select Business Type" onChange={this.handleInputChange}>
                                                                <option value="">Select Business Type</option>
                                                                {businessType &&
                                                                    businessType.map((business, index) => (
                                                                        <option key={index} value={business.id}>{business.name}</option>
                                                                    ))
                                                                }
                                                            </select> */}
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-md-4 col-12">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Aggregator Tags<span className="text-danger">*</span></label>
                                                            <Select
                                                                name="tags"
                                                                isClearable
                                                                isSearchable={true}
                                                                isMulti
                                                                // ref={el => this.salesChannelDropDown = el}
                                                                options={active_tags}
                                                                className="text-capitalize select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e: any) => this.handleStoreTags(e)}
                                                            />
                                                        </div>
                                                    </div> */}
                                                    {/* <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Branch Code <span className="text-danger">*</span></label>
                                                            <input id="fiscal_pos_code" type="text" name="fiscal_pos_code" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div> */}
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Email Contact <span className="text-danger">*</span></label>
                                                            <input id="storeemail" type="email" name="storeemail" required data-msg="Please enter Store Email" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Email Escalation</label>
                                                            <input id="escalationemail" type="email" name="escalationemail" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">NTN Number</label>
                                                            <input id="ntn" type="text" name="ntn" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Contact 1</label>
                                                            <input id="contact1" type="text" onKeyDown={this.blockInvalidNumberData} name="contact1" required data-msg="Please enter 1st Contact" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Contact 2</label>
                                                            <input id="contact2" type="text" onKeyDown={this.blockInvalidNumberData} name="contact2" required data-msg="Please enter 2nd Contact" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">RGM Contact</label>
                                                            <input id="rgmContact" type="text" onKeyDown={this.blockInvalidNumberData} name="rgmContact" required data-msg="Please enter RGM Contact" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Cloud Kitchen <span className="text-danger">*</span></label>
                                                            <Select
                                                                name="cloudKitchen"
                                                                isClearable
                                                                options={[{ label: 'Disabled', value: 'Disabled' }, { label: 'Enabled', value: 'Enabled' }]}
                                                                className="text-capitalize select mt-2"
                                                                classNamePrefix="select"
                                                                defaultValue={{ label: "Disabled", value: 'Disabled' }}
                                                                onChange={(e, i) => this.handleCloudKitchen(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Sale Channel <span className="text-danger">*</span></label>
                                                            <Select
                                                                name="saleschannle"
                                                                isClearable
                                                                ref={el => this.salesChannelDropDown = el}
                                                                options={salesChannelData}
                                                                className="text-capitalize select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleSalesChannel(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Store Type <span className="text-danger">*</span></label>
                                                            <Select
                                                                name="storetype"
                                                                isClearable
                                                                options={types}
                                                                className="text-capitalize select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleStoreTypes(e, i)}
                                                            />
                                                            {/* <select name="storetype" className="form-control text-capitalize mt-2" required data-msg="Please select Store Type" onChange={this.handleInputChange}>
                                                                <option value="">Select Type</option>
                                                                {types &&
                                                                    types.map((type, index) => (
                                                                        <option key={index} value={type.store_type_id}>{type.store_type_name}</option>
                                                                    ))
                                                                }
                                                            </select> */}
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Expected Time <span className="text-danger">* (In minutes)</span></label>
                                                            <input id="expected_delivery_time" min={0} type="number" name="expected_delivery_time" required data-msg="Please enter expected time" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">States <span className="text-danger">*</span></label>
                                                            <Select
                                                                name="state_id"
                                                                isClearable
                                                                value={{ label: this.state.state_name, value: this.state.state_id }}
                                                                options={states}
                                                                className="text-capitalize select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleStates(e, i)}
                                                            />
                                                            {/* <select name="state_id" className="form-control mt-2" required data-msg="Please select state" onChange={this.handleInputChange}>
                                                                <option value="">Select State</option>
                                                                {states.map((state: any, index: any) => (
                                                                    (state.payment_method == "cash") &&
                                                                    <option key={index} value={state.state_id} {...state_id === state.state_id && { selected: true }}>{state.state_name}</option>
                                                                ))
                                                                }
                                                            </select> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-lable">Store Timing <span className="text-danger">*</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <input id="storeopen" type="time" name="storeopen" required data-msg="Please enter opening time" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <input id="storeclose" type="time" name="storeclose" required data-msg="Please enter closing time" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div style={{ width: '100%' }}>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label htmlFor="">Latitude</label>
                                                                <input type="text" name="newlat" className="form-control" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label htmlFor="">Longitude</label>
                                                                <input type="text" name="newlng" className="form-control" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Map
                                                        data=""
                                                        center={{ lat: this.state.newlat, lng: this.state.newlng }}
                                                        radius={parseInt(this.state.radius)}
                                                        kml={this.state.kml}
                                                        onSetRadius={this.handleRadius}
                                                        onSetData={this.handleMapData}
                                                        google={this.props.google}
                                                        height='400px'
                                                        zoom={15}
                                                    />
                                                </div>
                                                <div className='row'>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Trade Zones <span className="text-danger">*</span></label>
                                                            <Select
                                                                name="tradeZone"
                                                                isClearable
                                                                options={tradezones}
                                                                className="text-capitalize select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleTradeZones(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">TradeZone Name <span className="text-danger">*</span></label>
                                                            <input id="tradezonename" type="text" name="tradezonename" required data-msg="Please enter TradeZone name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label mb-3">KML File <span className="text-danger">*</span></label>
                                                            <input id="files" type="file" name="files" className="form-control-file" onChange={this.fileSelectedHandler} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label ">KML Link <span className="text-danger">*</span></label>
                                                            <input id="kml" type="text" name="kml" defaultValue={this.state.kml} className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div> */}

                                                {!timeByDays &&
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Opening Time <span className="text-danger">*</span></label>
                                                                <input id="storeopen" type="time" name="storeopen" required data-msg="Please enter Opening Time" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Closing Time <span className="text-danger">*</span></label>
                                                                <input id="storeclose" type="time" name="storeclose" required className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="i-checks">
                                                            <input id="checkboxCustom1" type="checkbox" name="timeByDays" checked={timeByDays} onChange={this.handleTimeByDays} className="checkbox-template" />
                                                            <label htmlFor="checkboxCustom1">Add Time by Week Days</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                {timeByDays &&
                                                    <div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Day <span className="text-danger">*</span></label>
                                                                    <input id="day" type="text" readOnly value={'Monday'} name="day" required data-msg="Please enter Opening Time" className="input-material" />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Opening Time <span className="text-danger">*</span></label>
                                                                    <input id="mondayTime" type="time" name="mondayTime" required data-msg="Please enter Opening Time" className="input-material" onChange={(e) => this.dayOpeningTime(e)} />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Closing Time <span className="text-danger">*</span></label>
                                                                    <input id="mondayTime" type="time" name="mondayTime" required className="input-material" onChange={(e) => this.dayClosingTime(e)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Day <span className="text-danger">*</span></label>
                                                                    <input id="day" type="text" readOnly value={'Tuesday'} name="day" required data-msg="Please enter Opening Time" className="input-material" />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Opening Time <span className="text-danger">*</span></label>
                                                                    <input id="tuesdayTime" type="time" name="tuesdayTime" required data-msg="Please enter Opening Time" className="input-material" onChange={(e) => this.dayOpeningTime(e)} />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Closing Time <span className="text-danger">*</span></label>
                                                                    <input id="tuesdayTime" type="time" name="tuesdayTime" required className="input-material" onChange={(e) => this.dayClosingTime(e)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Day <span className="text-danger">*</span></label>
                                                                    <input id="day" type="text" readOnly value={'Wednesday'} name="day" required data-msg="Please enter Opening Time" className="input-material" />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Opening Time <span className="text-danger">*</span></label>
                                                                    <input id="wednesdayTime" type="time" name="wednesdayTime" required data-msg="Please enter Opening Time" className="input-material" onChange={(e) => this.dayOpeningTime(e)} />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Closing Time <span className="text-danger">*</span></label>
                                                                    <input id="wednesdayTime" type="time" name="wednesdayTime" required className="input-material" onChange={(e) => this.dayClosingTime(e)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Day <span className="text-danger">*</span></label>
                                                                    <input id="day" type="text" readOnly value={'Thursday'} name="day" required data-msg="Please enter Opening Time" className="input-material" />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Opening Time <span className="text-danger">*</span></label>
                                                                    <input id="thursdayTime" type="time" name="thursdayTime" required data-msg="Please enter Opening Time" className="input-material" onChange={(e) => this.dayOpeningTime(e)} />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Closing Time <span className="text-danger">*</span></label>
                                                                    <input id="thursdayTime" type="time" name="thursdayTime" required className="input-material" onChange={(e) => this.dayClosingTime(e)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Day <span className="text-danger">*</span></label>
                                                                    <input id="day" type="text" readOnly value={'Friday'} name="day" required data-msg="Please enter Opening Time" className="input-material" />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Opening Time <span className="text-danger">*</span></label>
                                                                    <input id="fridayTime" type="time" name="fridayTime" required data-msg="Please enter Opening Time" className="input-material" onChange={(e) => this.dayOpeningTime(e)} />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Closing Time <span className="text-danger">*</span></label>
                                                                    <input id="fridayTime" type="time" name="fridayTime" required className="input-material" onChange={(e) => this.dayClosingTime(e)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Day <span className="text-danger">*</span></label>
                                                                    <input id="day" type="text" readOnly value={'Saturday'} name="day" required data-msg="Please enter Opening Time" className="input-material" />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Opening Time <span className="text-danger">*</span></label>
                                                                    <input id="saturdayTime" type="time" name="saturdayTime" required data-msg="Please enter Opening Time" className="input-material" onChange={(e) => this.dayOpeningTime(e)} />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Closing Time <span className="text-danger">*</span></label>
                                                                    <input id="saturdayTime" type="time" name="saturdayTime" required className="input-material" onChange={(e) => this.dayClosingTime(e)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Day <span className="text-danger">*</span></label>
                                                                    <input id="day" type="text" readOnly value={'Sunday'} name="day" required data-msg="Please enter Opening Time" className="input-material" />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Opening Time <span className="text-danger">*</span></label>
                                                                    <input id="sundayTime" type="time" name="sundayTime" required data-msg="Please enter Opening Time" className="input-material" onChange={(e) => this.dayOpeningTime(e)} />
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Closing Time <span className="text-danger">*</span></label>
                                                                    <input id="sundayTime" type="time" name="sundayTime" required className="input-material" onChange={(e) => this.dayClosingTime(e)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                <div className="row" style={{ paddingTop: '1em' }}>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            {backupStore.map((x: any, i: any) => {
                                                                return (
                                                                    <div key={i} className="row">
                                                                        <div className="col">
                                                                            <div className="form-group">
                                                                                <label className="form-control-label">Backup Store</label>
                                                                                <Select
                                                                                    name="backUpStoreId"
                                                                                    isClearable
                                                                                    options={stores.filter(o1 => !backupStore.some((o2: any) => o1.store_id === o2.backUpStoreId))}
                                                                                    className="text-capitalize select mt-2"
                                                                                    classNamePrefix="select"
                                                                                    onChange={e => this.handleBackupStoreInputChange(e, i)} />
                                                                                {/* <select name="backUpStoreId" className="form-control text-capitalize mt-2" required data-msg="Please select Channel" onChange={e => this.handleBackupStoreInputChange(e, i)}>
                                                                                    <option value="">Backup Store</option>
                                                                                    {stores.length > 0 &&
                                                                                        stores.map((store: any, index: any) => (
                                                                                            <option key={index} value={store.store_id}>{store.store_name}</option>
                                                                                        ))
                                                                                    }
                                                                                </select> */}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="form-group">
                                                                                <label className="form-control-label">Priority</label>
                                                                                <input
                                                                                    name="priority"
                                                                                    type="number"
                                                                                    value={x.priority}
                                                                                    min={0}
                                                                                    disabled={x.backUpStoreId == ""}
                                                                                    onKeyDown={this.blockInvalidChar}
                                                                                    data-msg="Please enter qunatity"
                                                                                    className="input-material"
                                                                                    onChange={e => this.handleBackupStoreInputChange(e, i)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col py-4">
                                                                            {backupStore.length !== 1 &&
                                                                                <button className="btn btn-sm btn-outline-danger"
                                                                                    onClick={() => this.handleRemoveBackupStore(i)}><i className="fa fa-trash"></i></button>}
                                                                            {backupStore.length - 1 === i && <button className="btn btn-sm btn-primary ml-2" onClick={() => this.handleAddBackupStore(i)}><i className="fa fa-plus"></i></button>}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                                {/* <div className="row" style={{ paddingTop: '1em' }}>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            {tradeAreas.map((x: any, i: any) => {
                                                                return (
                                                                    <div key={i} className="row">
                                                                        <div className="col">
                                                                            <div className="form-group">
                                                                                <label className="form-control-label">TradeZone <span className="text-danger">*</span></label>
                                                                                <select name="tradeZone" className="form-control mt-2" required data-msg="Please Select TradeZone" onChange={e => this.handleTradeAreaInputChange(e, i)}>
                                                                                    <option value="">Select TradeZone</option>
                                                                                    {tradezones &&
                                                                                        tradezones.map((zone, index) => (
                                                                                            <option key={index} value={zone.id}>{zone.delivery_zone_name} ({zone.city})</option>
                                                                                        ))
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col">
                                                                            <div className="form-group">
                                                                                <label className="form-control-label">TradeArea name <span className="text-danger">*</span></label>
                                                                                <input id="tradeArea" type="text" name="tradeArea" value={x.tradeArea} required data-msg="Please enter TradeAreaName" className="input-material" onChange={e => this.handleTradeAreaInputChange(e, i)} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col py-4">
                                                                            {tradeAreas.length !== 1 &&
                                                                                <button className="btn btn-sm btn-outline-danger"
                                                                                    onClick={() => this.handleRemoveTradeArea(i)}><i className="fa fa-trash"></i></button>}
                                                                            {tradeAreas.length - 1 === i && <button className="btn btn-sm btn-primary ml-2" onClick={() => this.handleAddTradeArea(i)}><i className="fa fa-plus"></i></button>}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div> */}

                                                <div className="form-group float-right mt-3">
                                                    <button onClick={() => { this.props.history.push("/stores") }} className="btn btn-danger mr-2">Cancel</button>
                                                    <button className='btn btn-primary' disabled={!this.isStoreReady()} onClick={this.handleSaveBtnClick}>Save</button>
                                                </div>
                                            </div>
                                        </div >
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        message: state.store.message,
        isInserted: state.store.isInserted,
        brands: state.store.brands,
        channels: state.store.channels,
        states: state.setting.states,
        types: state.store.types,
        areas: state.store.areas,
        countries: state.store.countries,
        businessType: state.store.businessType,
        stores: state.store.data,
        tradezones: state.tradezone.tradezones
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        addStore: function (data: any) {
            dispatch(addStore(data));
        },
        addStates: function (data: any, type: any) {
            dispatch(addStates(data, type));
        },
        brandsList: function () {
            dispatch(brandsList())
        },
        channelsList: function () {
            dispatch(channelList())
        },
        storeTypeList: function () {
            dispatch(storeTypeList())
        },
        areasList: function () {
            dispatch(areasList())
        },
        countryList: function () {
            dispatch(countryList())
        },
        statesList: function (brand: any) {
            dispatch(statesList(brand, "store"))
        },
        businessTypeList: function () {
            dispatch(businessTypeList())
        },
        storesList: function () {
            dispatch(storesList())
        },
        tradeZoneList: () => { dispatch(tradeZoneList()) },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddStore);