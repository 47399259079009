export const instashop_categories = [
    { label: 'Egg',value:'egg'},
    { label: 'Non Veg',value:'non-veg'},
    { label: 'Veg',value:'veg'},
    { label: 'Cake',value:'cake'},
    { label: 'Chef Special',value:'chef-special'},
    { label: 'Contains Pork',value:'contains-pork'},
    { label: 'Dairy Free',value:'dairy-free'},
    { label: 'Fodmap Friendly',value:'fodmap-friendly'},
    { label: 'Gluten Free',value:'gluten-free'},
    { label: 'Lactose Free',value:'lactose-free'},
    { label: 'New',value:'new'},
    { label: 'Restaurant Recommended',value:'restaurant-recommended'},
    { label: 'Seasonal',value:'seasonal'},
    { label: 'Spicy',value:'spicy'},
    { label: 'Vegan',value:'vegan'},
    { label: 'Wheat Free',value:'wheat-free'},
    { label: 'Mrp Item',value:'mrp-item'},
    { label: 'Contains Alcohol',value:'contains-alcohol'},
    { label: 'Home Style Meal',value:'home-style-meal'},
    { label: 'Services',value:'services'},
    { label: 'Goods',value:'goods'},
    { label: 'Chocolate',value:'cake-flavor-chocolate'},
    { label: 'Black Forest',value:'cake-flavor-black-forest'},
    { label: 'Vanilla',value:'cake-flavor-vanilla'},
    { label: "Fruit",value:"cake-flavor-fruit"},
    { label: 'Pineapple',value:'cake-flavor-pineapple'},
    { label: "Futterscotch",value:"cake-flavor-butterscotch"},
    { label: 'Red Velvet',value:'cake-flavor-red-velvet'},
    { label: 'Blueberry',value:'cake-flavor-blueberry'},
    { label: 'Cheese Cake',value:'cake-flavor-cheesecake'},
    { label: 'Strawberry',value:'cake-flavor-strawberry'},
    { label: 'Cream',value:'cake-flavor-cream'},
    { label: 'Mango',value:'cake-flavor-mango'},
    { label: 'Cake',value:'cake'},
    { label: 'Anniversary Wedding Cake',value:'anniversary-wedding-cake'},
    { label: 'Tiered Cake',value:'tiered-cake'},
    { label: 'Birthday Cake',value:'birthday-cake'},
    { label: 'Kids Birthday Cake',value:'kids-birthday-cake'},
    { label: 'Gourmet Cake',value:'gourmet-cake'},
    { label: 'Premium Cake',value:'premium-cake'},
]