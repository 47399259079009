import React, { Component } from 'react';
import { EditStateProps, EditStates } from '../../../interfaces/settings'
import { connect } from 'react-redux'
import Topbar from '../../../components/topbar'
import Sidebar from '../../../components/sidebar'
import Footer from '../../../components/footer/main'
import { countryList, addStates, editStates, getState, brandsList } from '../../../redux'
import { Link, Redirect } from 'react-router-dom';
import jwt from 'jsonwebtoken'
import { secretKey } from '../../../secret'
import CheckChanges from '../../../components/confirmOnLeave'
import Select from 'react-select';
import { aggregatorsList } from '../../../redux/actions/aggregatorsAction';
class AddState extends Component<EditStateProps, EditStates> {
    constructor(props: any) {
        super(props);
        this.state = {
            statename: "",
            country: "",
            tax_type: "0",
            payment: "cash",
            timezone: "",
            brand_id:"",
            brand_name:"",
            aggregator_id:""
            // tax: ""
        }
        //tax Type 0 for inclusive tax
        //tax Type 1 for exclusive tax
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        document.title = "SimpleXSync | States"
        let id = this.props.match.params.id;
        this.props.brandsList()
        this.props.getState(id);
        this.props.countryList();
        this.props.aggregatorsList()
    }
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        this.setState({
            country: nextProps.stateData.country_id,
            statename: nextProps.stateData.state_name,
            tax_type: nextProps.stateData.tax_type,
            payment: nextProps.stateData.payment_method,
            timezone: nextProps.stateData.time_zone,
            brand_id:nextProps.stateData.brand_id,
            brand_name: nextProps.stateData.brand_name,
            aggregator_id:nextProps.stateData.aggregator_id
        })
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    isStateReady = () => {
        const { statename, country, tax_type, payment, timezone,brand_id } = this.state
        return (statename !== "" && country !== "" && tax_type !== "" && payment !== "" && timezone !== "" && timezone !== null && brand_id != '');
    }
    handleChangeRad = (event: { target: { name: any; value: any; } }) => {
        this.setState({ [event.target.name]: event.target.value })
    }
    handleSaveBtnClick = (event: any) => {
        let { statename, country, tax_type, payment, timezone,brand_id,aggregator_id } = this.state;
        let id = this.props.match.params.id;
        let data = {
            state_name: statename,
            country_id: country,
            tax_type: tax_type,
            payment_method: payment,
            time_zone: timezone,
            brand_id,
            aggregator_id
        }
        this.props.editStates(id, data);
    }
    handleBrandsInputChange = (e:any,i:any)=>{
        this.setState({brand_id:e.value,brand_name:e.label})
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let token: any = sessionStorage.getItem('token');
        let decoded:any = jwt.decode(token);
        let selectedBrands:any = []
        if(decoded.role_id == 7) {
            selectedBrands = this.props.brands
        } else {
            selectedBrands = this.props.brands.filter((data:any)=>{
                return data.brand_id == decoded.brand_id
            })
        }
        let msg;
        let messcolor;
        const { isUpdated, message, countries,aggregators } = this.props;
        if (!isUpdated) {
            msg = message;
            messcolor = 'red'
        }
        return (
            <div className="page">
                <CheckChanges path="/add-state" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header pb-1">
                            <div className="container-fluid">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h4>States Management</h4>
                                </div>
                            </div>
                        </header>
                        {/*  Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/states" className="text-primary">States</Link></li>
                                <li className="breadcrumb-item active">Edit State</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong>
                                                <div className="row">
                                                    <div className="col-lg-6 col-sm-6 col-6 mb-3" >
                                                        <div className="i-checks">
                                                            <input id="radioCustom1" type="radio" name="tax_type" value="0" checked={this.state.tax_type == '0'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom1">Inclusive tax menu prices</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-sm-6 col-6 mb-3" >
                                                        <div className="i-checks">
                                                            <input id="radioCustom2" type="radio" name="tax_type" value="1" checked={this.state.tax_type == '1'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom2">Exclusive tax menu prices</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">State name <span className="text-danger">*</span></label>
                                                            <input id="statename" type="text" name="statename" defaultValue={this.state.statename} required data-msg="Please enter State Name" className="input-material" onChange={this.handleInputChange} />
                                                            <small className="form-text">Please enter country name if you have same tax for all country.</small>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Country <span className="text-danger">*</span></label>
                                                            <select name="country" className="form-control mt-2" required data-msg="Please enter Country" onChange={this.handleInputChange}>
                                                                <option value=''>Select Country</option>
                                                                {countries &&
                                                                    countries.map((country: any, index: any) => (
                                                                        <option key={index} value={country.country_id} {...this.state.country == country.country_id && { selected: true }}>{country.country_name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col" >
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Select Brand <span className="text-danger">*</span></label>
                                                                    <Select
                                                                        name="brands"
                                                                        options={selectedBrands}
                                                                        value={{label:this.state.brand_name,value:this.state.brand_id}}
                                                                        className="text-capitalize basic-multi-select mt-2"
                                                                        classNamePrefix="select"
                                                                        onChange={(e, i) => this.handleBrandsInputChange(e, i)}
                                                                    />
                                                                </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            {console.log('timezone', this.state.timezone)
                                                            }
                                                            <label className="form-control-label">Time Zone <span className="text-danger">*</span></label>
                                                            <select name="timezone" className="form-control mt-2" required data-msg="Please enter Country" onChange={this.handleInputChange}>
                                                                <option value='' selected={this.state.timezone ? false : true}>Select Timezone</option>
                                                                <option selected={this.state.timezone ? true : false} value={this.state.timezone ? this.state.timezone : 'Asia/Karachi'}>{this.state.timezone ? this.state.timezone : 'Asia/Karachi'}</option>

                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Payment Method <span className="text-danger">*</span></label>
                                                            <select name="payment" className="form-control mt-2" onChange={this.handleInputChange}>
                                                                <option value="">Select Payment Method</option>
                                                                <option value="cash" {...this.state.payment == "cash" && { selected: true }}>Cash</option>
                                                                <option value="card" {...this.state.payment == "card" && { selected: true }}>Card</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Aggregator <span className="text-danger">*</span></label>
                                                            {/* <Select
                                                                defaultValue={{ label: 'Select Timezone', value: '' }}
                                                                options={[{ label: 'Asia/Karachi', value: 'Asia/Karachi' }]}
                                                            /> */}
                                                            <select name="aggregator_id" className="form-control mt-2" required data-msg="Please enter Country" onChange={this.handleInputChange}>
                                                                <option value="">Select Aggregator</option>
                                                                {aggregators && aggregators.map((agg:any)=>{
                                                                    return <option value={agg.id} {...(this.props.stateData.aggregator_id && this.props.stateData.aggregator_id === agg.id) && { selected: true }}>{agg.aggregator_name}</option>
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                {/* <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Tax value <span className="text-danger">*</span></label>
                                                            <input id="tax" type="text" name="tax" required data-msg="Please enter Tax" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="form-group float-right mt-3">
                                                    <button className='btn btn-primary' disabled={!this.isStateReady()} onClick={this.handleSaveBtnClick}>Save</button>
                                                </div>
                                            </div>
                                        </div >
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        countries: state.store.countries,
        stateData: state.setting.stateData,
        message: state.setting.message,
        isUpdated: state.setting.isUpdated,
        brands:state.brand.brands,
        aggregators:state.aggregator.aggregators

    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        editStates: function (id: any, data: any) {
            dispatch(editStates(id, data));
        },
        getState: (id: any) => {
            dispatch(getState(id))
        },
        countryList: function () {
            dispatch(countryList())
        },
        brandsList: function () {
            dispatch(brandsList())
        },
        aggregatorsList:() => { 
            dispatch(aggregatorsList())
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddState);