import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import { connect } from 'react-redux'
import { logoutUser, getTaxValue, saveDiscountValue, countryList, saveDeliveryFee, discountsList, statesList, saveTaxByState, rolesList, roleFeatures, updateFeaturesAccess, brandsList } from '../../redux'
import { SettingsProps, SettingsState } from '../../interfaces/settings';
import Toggle from 'react-toggle';
import Select from 'react-select';
import { roleFeaturesByBrand } from '../../redux/actions/settingsAction';
class Settings extends Component<{brandsList:()=>{},brands:any}, { [x: number]: any, role: any, role_type: any,brand_id:any }> {
    constructor(readonly props: any) {
        super(props);
        this.state = {
            role: "",
            role_type: "",
            brand_id:""
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleToggleChange = this.handleToggleChange.bind(this);
    }
    componentDidMount() {
        this.props.brandsList()
        this.props.rolesList();
        document.title = "SimpleXSync | I A M"
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
        if (event.target.name == 'role') {
            let type = "";
            this.props.roles.find((obj: any) => {
                if (obj.role_id == event.target.value) {
                    type = obj.type;
                }
            })
            if (this.state.brand_id != '') {
                console.log(this.state.brand_id,event.target.value,type)
                this.props.roleFeatures(this.state.brand_id,event.target.value, type);
            }
            // this.props.roleFeatures(event.target.value, type);
            this.setState({ role: event.target.value, role_type: type });
        }
    }
    handleToggleChange(e: any, element: any) {
        let { role,role_type,brand_id } = this.state;
        let body: any = {
            permission: e.target.checked == false ? 0 : 1,
            feature_id: element.feature_id.id,
            role_id: role,
            brand_id
        }
        this.props.updateFeaturesAccess(body,role_type);
    }
    handleBrandsInputChange = (e:any,i:any)=>{
        this.setState({brand_id:e.value})
        if (this.state.role !== "") {
            console.log(this.state.brand_id,e.value,this.state.role_type)
            this.props.roleFeatures(e.value,this.state.role, this.state.role_type);
        }
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let token: any = sessionStorage.getItem('token');
        let decoded:any = jwt.decode(token);
        let selectedBrands:any = []
        if(decoded.role_id == 7) {
            selectedBrands = this.props.brands
        } else {
            selectedBrands = this.props.brands.filter((data:any)=>{
                return data.brand_id == decoded.brand_id
            })
        }
        const { roles, features } = this.props;
        return (
            <div className="page">
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/* <!-- Page Header--> */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Identity and access management</h4>
                                </div>
                            </div>
                        </header>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12 col-12">
                                        <div className="card-header">
                                            <div className="row">
                                            <div className="col" >
                                                                <div className="form-group">
                                                                    <Select
                                                                        name="brands"
                                                                        options={selectedBrands}
                                                                        className="text-capitalize basic-multi-select mt-2"
                                                                        classNamePrefix="select"
                                                                        onChange={(e, i) => this.handleBrandsInputChange(e, i)}
                                                                    />
                                                                </div>
                                            </div>
                                                <div className="col">
                                                    <div className="form-group">
                                                        <select name="role" className="form-control text-capitalize mt-2" required data-msg="Please select Menu" onChange={this.handleInputChange}>
                                                            <option value="">Select Role</option>
                                                            {roles &&
                                                                roles.map((role: any, index: any) => (
                                                                    (role.role_id !== 1 && role.role_id !== 6 && role.role_id !== 7) && <option key={index} value={role.role_id}>{role.role_name}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-body text-capitalize">
                                                <div className="row">
                                                    {this.state.role!=="" && features.length > 0 ? features.map((element: any, index: any) => {
                                                        return (
                                                            <div className="col-lg-6 my-2" key={index}>
                                                                <div className="d-flex justify-content-between">
                                                                    <div>{element.feature_id.feature_name}</div>
                                                                    <div>
                                                                        <Toggle
                                                                            className='custom-classname'
                                                                            checked={element.permission == 0 ? false : true}
                                                                            icons={false}
                                                                            onChange={(e: any) => this.handleToggleChange(e, element)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }) :
                                                        <div className="col-12 text-center">
                                                            <h5>Features not found</h5>
                                                        </div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div >
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        message: state.setting.message,
        discounts: state.setting.discounts,
        discountMsg: state.setting.discMsg,
        states: state.setting.states,
        countries: state.store.countries,
        isSaved: state.setting.isSaved,
        isInserted: state.setting.isInserted,
        tax: state.setting.taxData,
        deliveryMsg: state.setting.deliveryMsg,
        saveDelivery: state.setting.saveDelivery,
        roles: state.setting.roles,
        features: state.setting.features,
        brands:state.brand.brands
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        countryList: function () {
            dispatch(countryList())
        },
        discountsList: function () {
            dispatch(discountsList())
        },
        statesList: function () {
            dispatch(statesList())
        },
        saveTaxByState: function (data: any) {
            dispatch(saveTaxByState(data))
        },
        getTaxValue: function () {
            dispatch(getTaxValue());
        },
        saveDiscountValue: function (data: any) {
            dispatch(saveDiscountValue(data))
        },
        saveDeliveryFee: function (data: any) {
            dispatch(saveDeliveryFee(data))
        },
        rolesList: function () {
            dispatch(rolesList())
        },
        roleFeatures: function (brand_id:any,role_id: any, type: any) {
            dispatch(roleFeaturesByBrand(brand_id,role_id, type))
        },
        updateFeaturesAccess: function (body: any,type:any) {
            dispatch(updateFeaturesAccess(body,type))
        },
        brandsList: function () {
            dispatch(brandsList())
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Settings);