import React, { Component } from 'react';
import { AddDiscountProps, AddDiscountState } from '../../interfaces/discounts'
import { connect } from 'react-redux'
import { brandsList, menusList, groupsList, logoutUser, addDiscount, groupsListForMultiSelect, itemsListForMultiSelect } from '../../redux'
import { Link, Redirect } from 'react-router-dom'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../secret'
import Topbar from '../../components/topbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer/main'
import Select from 'react-select';
import CheckChanges from '../../components/confirmOnLeave'
import { aggregatorsList } from '../../redux/actions/aggregatorsAction';
import { menusListForMaster } from '../../redux/actions/menuAction';
const ShortUniqueId = require('short-unique-id');
const uid = new ShortUniqueId();
class AddDiscount extends Component<AddDiscountProps, AddDiscountState> {
    orderModes: { value: string; label: string; }[];
    constructor(props: any) {
        super(props);
        this.orderModes = [
            { value: 'online', label: 'Online' },
            { value: 'mobile', label: 'Mobile' },
            { value: 'callcenter', label: 'Call Center' }
        ];
        this.state = {
            discountvalue: "",
            aggregator_id:"",
            expiryDate: "",
            percent: "",
            mode: [],
            brand_id:"",
            channel: "delivery",
            type: "menu",
            discountType: "value",
            multiJson: [],
            type_id: "",
            erp_id:""
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDiscountLevel = this.handleDiscountLevel.bind(this);
        // this.handleDiscountValue=this.handleDiscountValue.bind(this);
        this.handleDiscountType = this.handleDiscountType.bind(this);
    }
    componentDidMount() {
        document.title = "SimpleXSync | Discounts"
        this.props.aggregatorsList()
        this.props.brandsList()
        this.props.itemsListForMultiSelect();
        this.props.groupsList();
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }
    // handleDiscountType(event: { target: { name: any; value: any; }; }) {
    //     this.setState({
    //         type_id:"",
    //         [event.target.name]: event.target.value,
    //     }); 
    // }
    handleGroupsInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ multiJson: e });
        }
    };
    handleItemsInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ multiJson: e });
        }
    }
    handleOrderModesInputChange = (e: any, index: any) => {
        if (e && e.length > 0) {
            this.setState({ mode: e });
        } else {
            this.setState({ mode: [] });
        }
    };
    handleDiscountType(event: { target: { name: any; value: any; }; }) {
        this.setState({
            percent: "",
            discountvalue: "",
            [event.target.name]: event.target.value,
        });
    }

    isDiscountReady = () => {
        const { expiryDate, mode, discountType,erp_id, discountvalue, type, type_id, multiJson, percent,aggregator_id,brand_id } = this.state;
        if (type == "menu") {
            return (expiryDate !== "" && erp_id!='' && mode.length > 0 && type_id !== "" && discountType !== "" && (percent !== "" || discountvalue !== "" && aggregator_id!='' && brand_id != ''));
        } else if (type == "group" || type == "item") {
            return (expiryDate !== "" && erp_id!='' && mode.length > 0 && multiJson.length > 0 && discountType !== "" && (percent !== "" || discountvalue !== "" && aggregator_id!='' && brand_id != ''));
        }
    }
    handleSaveBtnClick = (history: any) => {
        let { discountvalue, expiryDate,erp_id, mode,aggregator_id,brand_id, discountType, type, type_id, multiJson, percent } = this.state;
        let data: any = {
            discount_value:discountvalue ? Number(discountvalue) : 0,
            discount_type: discountType,
            expire_date: expiryDate,
            mode: JSON.stringify(mode),
            type: type,
            percent:percent ? Number(percent) : 0,
            aggregator_id,
            brand_id,
            erp_id
        }
        if (type == "menu") {
            data.type_id = type_id;
        } else if (type == "group" || type == "item") {
            data.items_json = JSON.stringify(multiJson);
        }
        this.props.addDiscount(data, history);
    }

    handleDiscountLevel(event: { target: { name: any; value: any; }; }) {
        this.setState({
            type_id: "",
            [event.target.name]: event.target.value,
        });
    }

    handleBrandsInputChange = (e:any,i:any)=>{
        this.setState({brand_id:e.value})
        this.props.menusList(e.value);
    }

    handleMenuType = (e:any) => {
        this.setState({aggregator_id:e.target.value})
    }
    handleErpChange = (value:any) => {
        this.setState({erp_id:value})
    }
    blockInvalidChar = (e: any) =>
        ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
        
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let token: any = sessionStorage.getItem('token');
        let decoded:any = jwt.decode(token);
        let selectedBrands:any = []
        if(decoded.role_id == 7) {
            selectedBrands = this.props.brands
        } else {
            selectedBrands = this.props.brands.filter((data:any)=>{
                return data.brand_id == decoded.brand_id
            })
        }
        let msg;
        let messcolor;
        const { isInserted, message, menus, items } = this.props;
        if (!isInserted) {
            msg = message;
            messcolor = 'red'
        }
        return (
            <div className="page">
                <CheckChanges path="/add-coupon" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">Compaigns Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/marketing/2" className="text-primary">Compaigns</Link></li>
                                <li className="breadcrumb-item active">Add Discount</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <strong><p className="text-center font-weight-light mb-4" style={{ color: messcolor }}>
                                                    {msg}
                                                </p></strong>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Discount Level <span className="text-danger">*</span></label>
                                                            <select name="type" className="form-control text-capitalize mt-2" required data-msg="Please select Type" onChange={this.handleDiscountLevel}>
                                                                <option value='menu'>Menu</option>
                                                                <option value='group'>Group</option>
                                                                <option value='item'>Menu Item</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col" >
                                                                <div className="form-group">
                                                                    <label className="form-control-label">Select Brand <span className="text-danger">*</span></label>
                                                                    <Select
                                                                        name="brands"
                                                                        options={selectedBrands}
                                                                        className="text-capitalize basic-multi-select mt-2"
                                                                        classNamePrefix="select"
                                                                        onChange={(e, i) => this.handleBrandsInputChange(e, i)}
                                                                    />
                                                                </div>
                                                    </div>
                                                    {this.state.type === "menu" &&
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Select Menu <span className="text-danger">*</span></label>
                                                                <select name="type_id" className="form-control text-capitalize mt-2" required data-msg="Please select Group" onChange={this.handleInputChange}>
                                                                    <option>Select Menu</option>
                                                                    {menus &&
                                                                        menus.map((menu: any, index: any) => (
                                                                            <option key={index} value={menu.menu_id}>{menu.menu_name}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                    }

                                                    {this.state.type === "group" &&
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Select Group <span className="text-danger">*</span></label>
                                                                <Select
                                                                    isMulti
                                                                    name="groups"
                                                                    options={this.props.groups}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleGroupsInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    {this.state.type === "item" &&
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Select Items <span className="text-danger">*</span></label>
                                                                <Select
                                                                    isMulti
                                                                    name="items"
                                                                    options={items}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleItemsInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    
                                                </div>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Discount Type <span className="text-danger">*</span></label>
                                                            <select name="discountType" className="form-control text-capitalize mt-2" required data-msg="Please select Type" onChange={this.handleDiscountType}>
                                                                <option value='value'>Value</option>
                                                                <option value='percentage'>Percentage</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    {this.state.discountType === "percentage" &&
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Discount Percentage <span className="text-danger">*</span></label>
                                                                <input id="percent" type="text" name="percent" min="1" onKeyDown={this.blockInvalidChar} required data-msg="Please enter Discount Percentage" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    }
                                                    {this.state.discountType === "value" &&
                                                        <div className="col">
                                                            <div className="form-group">
                                                                <label className="form-control-label">Discount value <span className="text-danger">*</span></label>
                                                                <input id="discountvalue" type="text" min="1" onKeyDown={this.blockInvalidChar} name="discountvalue" required data-msg="Please enter Discount Value" className="input-material" onChange={this.handleInputChange} />
                                                            </div>
                                                        </div>
                                                    }

<div className="col">
                                                    <div className="form-group">
                                                        <label className="form-control-label">
                                                            Aggregator <span className="text-danger">*</span>
                                                        </label>
                                                        <select
                                                            name="aggregator_id"
                                                            className="form-control text-capitalize mt-2"
                                                            required
                                                            data-msg="Please select menu type"
                                                            onChange={this.handleMenuType}
                                                        >
                                                            <option value="">Select Aggregator</option>
                                                            {this.props.aggregators.map((data:any)=>{
                                                            return <option value={data.id}>{data.aggregator_name}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Valid till <span className="text-danger">*</span></label>
                                                            <input id="expiryDate" type="date" name="expiryDate" required data-msg="Please enter Expire Date" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4" >
                                                        <div className="form-group">
                                                            <label className="form-control-label">Ordering Mode <span className="text-danger">*</span></label>
                                                            <Select
                                                                isMulti
                                                                name="mode"
                                                                options={this.orderModes}
                                                                className="text-capitalize basic-multi-select mt-2"
                                                                classNamePrefix="select"
                                                                onChange={(e, i) => this.handleOrderModesInputChange(e, i)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                           <div className="form-group">
                                                              <label className="form-control-label mb-3">Erp Id <span className="text-danger">*</span></label>
                                                                  <input
                                                                   disabled
                                                                   value={this.state.erp_id}
                                                                   name="erp_id"
                                                                   type="text"
                                                                   data-msg="Please enter erp"
                                                                  className="input-material"
                                                                                        // onChange={e => this.handleVariantInputChange(e, ind)}
                                                                  />
                                                            </div>
                                                    </div>

                                                     <div className="col-md-1">
                                                         <button onClick={()=>this.handleErpChange(uid.stamp(32))} className="btn btn-sm btn-primary"><i className="fa fa-random"></i></button>
                                                       </div>
                                                </div>
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    <button className='btn btn-primary' disabled={!this.isDiscountReady()} onClick={() => this.handleSaveBtnClick(this.props.history)}>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div >
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        brands: state.store.brands,
        menus: state.menu.menus,
        groups: state.menu.groupsptions,
        items: state.menu.allActiveItems,
        message: state.discount.message,
        isInserted: state.discount.isInserted,
        aggregators:state.aggregator.aggregators
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        logoutUser: function () {
            dispatch(logoutUser());
        },
        addDiscount: function (data: any, history: any) {
            dispatch(addDiscount(data, history));
        },
        brandsList: function () {
            dispatch(brandsList())
        },
        menusList: function (type:any) {
            dispatch(menusListForMaster(type))
        },
        groupsList: function () {
            dispatch(groupsListForMultiSelect())
        },
        itemsListForMultiSelect: function () {
            dispatch(itemsListForMultiSelect())
        },aggregatorsList:() => { 
            dispatch(aggregatorsList())
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddDiscount);