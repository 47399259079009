import React, { Component } from 'react';
import { AddGroupProps, AddGroupState, AddSubGroupProps, AddSubGroupState } from '../../../interfaces/menu'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
import { secretKey } from '../../../secret'
import { addGroup, brandsList, logoutUser, storesListForMultiSelect } from '../../../redux'
import { Link, Redirect } from 'react-router-dom';
import Topbar from '../../../components/topbar'
import Sidebar from '../../../components/sidebar'
import Footer from '../../../components/footer/main'
import CheckChanges from '../../../components/confirmOnLeave'
import Select from 'react-select';
import { addSubGroup, groupsListFilter, menusOptions, subGroupsByGroup } from '../../../redux/actions/menuAction';
import * as uuid from 'uuid'
const ShortUniqueId = require('short-unique-id');
const uid = new ShortUniqueId();

class AddSubGroup extends Component<AddSubGroupProps, AddSubGroupState> {
    constructor(props: any) {
        super(props);
        this.state = {
            sub_group_name: "",
            image: "",
            disabled:false,
            type:"",
            brand_id:"",
            priority: "",
            erp_id: null,
            group_id:''
        }
        this.handleSaveBtnClick = this.handleSaveBtnClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        this.props.brandsList()
        // this.props.groupsList("master");
        // this.props.storesList();
        document.title = "SimpleXSync | Groups"
    }
    handleInputChange(event: { target: { name: any; value: any; }; }) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleGroups = (e:any) => {
        this.setState({group_id:e.target.value})
        // this.props.subGroupsByGroup(e.value)
    }
    blockInvalidChar = (e: any) => {
        var regex = new RegExp("^[a-zA-Z0-9]");
        let specialkey: any = e.keyCode;
        var key: any = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (specialkey == 8 || specialkey == 9) {
            return true;
        } else {
            if (/\S/.test(e.target.value)) {
                // string is not empty and not just whitespace
                if (!regex.test(key)) {
                    if (specialkey == 32) {
                    } else {
                        e.preventDefault()
                    }
                }
            } else {
                ((!regex.test(key) || specialkey == 32)) && e.preventDefault()
            }
        }
    }
    fileSelectedHandler = (e: any) => {
        this.setState({ image: e.target.files })
    }
    isGroupReady = () => {
        const { sub_group_name, image, group_id,type,disabled } = this.state
            return (sub_group_name !== "" && image !== "" && group_id != '' && type !='' && disabled!=true);
        
    }
    handleErpChange = () => {
        this.setState({erp_id:uid.stamp(32)})
    }
    handleState = (value:any)=>{
        this.setState({disabled:value})
    }
    handleSaveBtnClick = () => {
        this.setState({disabled:true})
        let { sub_group_name,brand_id, image, priority, erp_id, group_id,type } = this.state;
        const data: any = new FormData();
        data.append('file', image[0])
        data.append('sub_group_name', sub_group_name)
        data.append("brand_id",brand_id)
        data.append('group_id', group_id)
        data.append('erp_id', erp_id)
        data.append('priority', priority)
        data.append('type', type)
        this.props.addGroup(data,this.handleState);
    }
    handleBrandsInputChange = (e:any,i:any)=>{
        this.setState({brand_id:e.value})
        this.props.groupsList("master",e.value);
        this.props.storesList(e.value);
    }
    render() {
        if (sessionStorage.token) {
            jwt.verify(sessionStorage.token, secretKey, (err: any, decoded: any) => {
                if (err) {
                    this.props.logoutUser();
                }
            });
        } else {
            return <Redirect to="/" />
        }
        let token: any = sessionStorage.getItem('token');
        let decoded:any = jwt.decode(token);
        let role_id:any = decoded.role_id
        let selectedBrands:any = []
        if(decoded.role_id == 7) {
            selectedBrands = this.props.brands
        } else {
            selectedBrands = this.props.brands.filter((data:any)=>{
                return data.brand_id == decoded.brand_id
            })
        }
        const { groups } = this.props;
        return (
            <div className="page">
                <CheckChanges path="/add-group" />
                <Topbar />
                <div className="page-content d-flex align-items-stretch">
                    <Sidebar />
                    <div className="content-inner">
                        {/*  Page Header */}
                        <header className="page-header py-0">
                            <div className="container-fluid">
                                <div className="d-flex justify-content-between py-3">
                                    <h4 className="mt-2">SubGroups Management</h4>
                                </div>
                            </div>
                        </header>
                        {/* Breadcrumb */}
                        <div className="breadcrumb-holder container-fluid">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/groups" className="text-primary">Groups</Link></li>
                                <li className="breadcrumb-item active">Add SubGroup</li>
                            </ul>
                        </div>
                        <section className="forms">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-lg-4 col-6">
                                                        <div className="form-group">
                                                            <label className="form-control-label">SubGroup name <span className="text-danger">*</span></label>
                                                            <input id="sub_group_name" type="text" name="sub_group_name" onKeyDown={this.blockInvalidChar} required data-msg="Please enter Menu Name" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-3 col-5">
                                                        <div className="form-group">
                                                            <label className="form-control-label">ERP Id</label>
                                                            <input disabled id="erp_id" value={this.state.erp_id} type="text" name="erp_id" onKeyDown={this.blockInvalidChar} required data-msg="Please enter Menu Name" className="input-material" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-1 col-1 mt-5">
                                                         <button onClick={this.handleErpChange} className="btn btn-sm btn-primary"><i className="fa fa-random"></i></button>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Type<span className="text-danger">*</span></label>
                                                            <select name="type" className="form-control text-capitalize mt-2" required data-msg="Please select Menu" onChange={this.handleInputChange}>
                                                                <option value=''>Select Type</option>
                                                                <option value='item'>Item</option>
                                                                <option value='combo'>Combo</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                <div className='col-lg-4'>
                                                            <label className="form-control-label">Select Brand <span className="text-danger">*</span></label>
                                                            <div className="form-group">
                                                                <Select
                                                                    name="brands"
                                                                    options={selectedBrands}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    // value={{label:this.state.brand_name,value:this.state.brand_id}}
                                                                    onChange={(e, i) => this.handleBrandsInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Group <span className="text-danger">*</span></label>
                                                            <select name="menu" className="form-control text-capitalize mt-2" required data-msg="Please select Menu" onChange={this.handleGroups}>
                                                                <option>Select Group</option>
                                                                {groups &&
                                                                    groups.map((group, index) => (
                                                                        <option key={index} value={group.group_id}>{group.group_name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label mb-3">SubGroup Image <span className="text-danger">*</span></label>
                                                            <input id="file" type="file" name="file" className="form-control-file" onChange={this.fileSelectedHandler} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <div className="form-group">
                                                            <label className="form-control-label">Priority</label>
                                                            <input id="priority" type="number" name="priority" onKeyDown={this.blockInvalidChar} required data-msg="Please enter Priority" className="input-material" onChange={this.handleInputChange} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="row">
                                                    <div className="col-lg-6 col-sm-6 col-6 mt-5" >
                                                        <div className="i-checks">
                                                            <input id="radioCustom3" type="radio" name="is_lsm" value="0" checked={is_lsm == '0'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom3">Available for all stores</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-sm-6 col-6 mt-5" >
                                                        <div className="i-checks">
                                                            <input id="radioCustom4" type="radio" name="is_lsm" value="1" checked={is_lsm == '1'} onChange={this.handleChangeRad} className="radio-template" />
                                                            <label htmlFor="radioCustom4">Available for specific stores</label>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                {/* <div className="row">
                                                    {
                                                        is_lsm == '1' &&
                                                        <div className="col" >
                                                            <div className="form-group">
                                                                <label className="form-control-label">Select Store <span className="text-danger">*</span></label>
                                                                <Select
                                                                    isMulti
                                                                    defaultValue={storesJson}
                                                                    name="stores"
                                                                    options={this.props.stores}
                                                                    className="text-capitalize basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    onChange={(e, i) => this.handleStoresInputChange(e, i)}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div> */}
                                                <div className="form-group d-flex justify-content-end mt-4">
                                                    <button className='btn btn-primary' disabled={!this.isGroupReady()} onClick={this.handleSaveBtnClick}>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Footer />
                    </div>
                </div>
            </div >

        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        groups: state.menu.groups,
        // subgroups: state.menu.subgroups,
        stores: state.menu.storesoptions,
        brands:state.brand.brands
    }
}
const mapDispatchToProps = (dispatch: any) => {
    return {
        addGroup: function (data: any,handleState:any) {
            dispatch(addSubGroup(data,handleState));
        },
        groupsList: function (menu_type:any,brand_id: any) {
            dispatch(groupsListFilter(menu_type,brand_id))
        },
        // subGroupsByGroup:(id:any) => {
        //     dispatch(subGroupsByGroup(id))
        // },
        storesList: (brand_id:any) => {
            dispatch(storesListForMultiSelect(brand_id))
        },
        logoutUser: function () {
            dispatch(logoutUser());
        },
        brandsList: function () {
            dispatch(brandsList())
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddSubGroup);